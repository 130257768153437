import { Injectable } from '@angular/core';

import { ProjectService } from './../project/project.service';
import { UtilService } from './../util/util.service';
import { CompanyService } from './../company/company.service';

import * as _ from 'lodash';
import PouchDB from 'pouchdb-browser';

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  localStorage: any = window.localStorage;

  teams: any[] = [];
  managerTeams: any[] = [];
  teamsMap: any = {};

  // Map of team_keys and their employees eg. { team_key: { employee_key: {}, employee_key: {}... }}
  teamEmployeeMap: any = null;

  selectedTeam: any = null;

  db: any;

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public projectService: ProjectService,
    public companyService: CompanyService
  ) { }


  initialiseService() {
    return new Promise<any>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve(this.teams);
      }
      else {
        this.loadEmployeeTeams()
          .then(() => {
            this.serviceSetup = true;
            resolve(this.teams);
          })
          .catch(() => {
            reject();
          });
      }
    });
  }

  clearServiceData(clearDatabases) {
    this.teams = [];
    this.teamsMap = {};
    this.managerTeams = [];
    this.teamEmployeeMap = null;

    if (clearDatabases) {
      this.clearCachedSelectedTeam();
      this.clearTeamsFromDB();
    }
    this.serviceSetup = false;
  }

  getTeams() {
    return this.teams;
  }

  hasMultipleTeams(): boolean {
    return this.teams?.length > 1;
  }

  getSelectedTeamEmployeeMap() {
    if (this.selectedTeam === null) {
      return null;
    }
    else {
      return this.teamEmployeeMap[this.selectedTeam.team_key];
    }
  }

  getManagerTeams() {
    return this.managerTeams;
  }

  getSelectedTeam() {
    return this.selectedTeam;
  }

  setSelectedTeam(team) {
    this.selectedTeam = team;

    if (this.selectedTeam !== null) {
      this.cacheSelectedTeam(team);
    }
    else {
      this.cacheSelectedTeam("all");
    }
  }

  initDB() {
    try {
      this.db = new PouchDB("ph-droppah-teams", {
        adapter: "idb"
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  loadTeamsIntoDB(teams) {
    if (this.db) {
      let dbTeams = _.cloneDeep(teams);

      for (let i = 0; i < dbTeams.length; i++) {
        let team = dbTeams[i];

        // Set project database ID as their project key
        // ID needs to be a string
        team._id = team.team_key + "";
      }

      this.db.bulkDocs(dbTeams)
        .catch((err) => {
          console.log(err);
        });
    }
  }

  loadTeamsFromDB() {
    return new Promise<any>((resolve, reject) => {
      if (this.db) {
        this.db.allDocs({ include_docs: true })
          .then((docs) => {
            let teams = [];

            for (let i = 0; i < docs.rows.length; i++) {
              let doc = docs.rows[i].doc;

              // Remove DB related properties from project
              delete doc._id;
              delete doc._rev;

              teams.push(doc);
              resolve(teams);
            }
          })
          .catch(() => {
            reject();
          });
      }
      else {
        reject();
      }
    });
  }

  clearTeamsFromDB() {
    return new Promise<void>((resolve, reject) => {
      if (this.db) {

        this.db.destroy()
          .then(() => {
            this.initDB();
            resolve();
          })
          .catch((err) => {
            console.log(err);
            resolve();
          });
      }
      else {
        resolve();
      }
    });
  }

  loadEmployeeTeams() {
    return new Promise((resolve, reject) => {
      let params = {
        employee_key: this.companyService.getEmployeeKey()
      };

      this.utilService.APIGet('team', params, false, null, false)
        .then((data) => {
          this.loadTeamsIntoDB(data);

          setupTeamsArrays(data);
        })
        // Fallback to loading teams from IndexedDB
        .catch(() => {
          this.loadTeamsFromDB()
            .then((data) => {
              setupTeamsArrays(data);
            })
            .catch(() => {
              reject();
            });
        });

      let setupTeamsArrays = (data) => {
        this.teams = [];
        this.teamsMap = {};

        for (let i = 0; i < data.length; i++) {
          let team = TeamService.setupTeam(data[i]);

          this.teamsMap[team.team_key] = team;
          this.teams.push(team);
        }

        let selectedTeam = this.getCachedSelectedTeam();

        if (selectedTeam && selectedTeam !== "all") {
          this.setSelectedTeam(selectedTeam);
        }
        else if (selectedTeam === "all") {
          this.setSelectedTeam(null);
        }
        else {
          // If there is only one team then set it by default
          if (this.teams.length === 1) {
            this.setSelectedTeam(this.teams[0]);
          }
          else {
            this.setSelectedTeam(null);
          }
        }

        this.setupManagerTeams();
        this.setupTeamEmployeeMap();

        resolve(this.teams);
      }
    });
  }

  /**
   * Determines whether or not the given employee has access
   * to record time on any of the teams they are apart of
   *
   * @param {Number} employee_key
   * @returns {boolean}
   */
  timeDisabledOnAllEmployeesTeams(employee_key) {
    // If the employee isn't on any teams,
    // then they should have access to time recording by default
    if (this.teams.length === 0) {
      return false;
    }
    else {
      let foundTeamWithTimeEnabled = false;

      for (let i = 0; i < this.teams.length; i++) {
        let team = this.teams[i];

        for (let j = 0; j < team.employees.length; j++) {
          let emp = team.employees[j];

          if (emp.employee_key === employee_key) {
            if (!emp.disabled_time_flag) {
              foundTeamWithTimeEnabled = true;
            }
          }
        }
      }

      return !foundTeamWithTimeEnabled;
    }
  }

  setupManagerTeams() {
    this.managerTeams = [];

    for (let i = 0; i < this.teams.length; i++) {
      let team = this.teams[i];

      for (let j = 0; j < team.employees.length; j++) {
        let emp = team.employees[j];

        if (emp.employee_key === this.companyService.getEmployeeKey()) {
          if (emp.manager_flag) {
            this.managerTeams.push(team);
          }
        }
      }
    }
  }

  setupTeamEmployeeMap() {
    this.teamEmployeeMap = {};

    for (let i = 0; i < this.teams.length; i++) {
      let team = this.teams[i];

      this.teamEmployeeMap[team.team_key] = {};

      for (let j = 0; j < team.employees.length; j++) {
        let employee = team.employees[j];

        this.teamEmployeeMap[team.team_key][employee.employee_key] = employee;
      }
    }
  }

  getTeamEmployeesByProject(team_key) {
    let team = this.teamsMap[team_key];

    if (team) {
      let projectEmployees = [{
        project: team.default_project,
        employees: []
      }];

      for (let i = 0; i < team.employees.length; i++) {
        let emp = team.employees[i];

        // Employee doesn't have custom default_project so
        // add them to the team's default project list of employees
        if (emp.default_project === null) {
          projectEmployees[0].employees.push(emp);
        }
        // Employee default_project is different to the team default_project
        else {
          let foundProject = false;

          for (let j = 0; j < projectEmployees.length; j++) {
            let projEmp = projectEmployees[j];

            if (projEmp.project.project_key === emp.default_project_key) {
              projEmp.employees.push(emp);
              foundProject = true;
            }
          }

          if (!foundProject) {
            projectEmployees.push({
              project: emp.default_project,
              employees: [emp]
            });
          }
        }

      }

      return projectEmployees;
    }
    else {
      return null;
    }
  }

  /**
   * Formats non primitive team properties
   * @param {Object} team
   * @returns {Object} team
   */
  static setupTeam(team) {
    if (!team.project) {
      team.project = [];
    }
    if (!team.employees) {
      team.employees = [];
    }

    for (const project of team.projects) {
      project.project_colour = UtilService.intToHexColor(project.project_colour);
      project.deleted_flag = false;
    }

    for (const employee of team.employees) {
      employee.deleted_flag = false;
    }

    return team;
  }

  getCachedSelectedTeam() {
    let teamData = this.localStorage.getItem("ph_shift_team");

    if (teamData) {
      return teamData === "all" ? teamData : JSON.parse(teamData);
    }
    return null;
  }

  cacheSelectedTeam(team) {
    this.localStorage.setItem("ph_shift_team", JSON.stringify(team));
  }

  clearCachedSelectedTeam() {
    this.localStorage.removeItem("ph_shift_team");
  }

}
