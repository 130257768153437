import { Pipe, PipeTransform } from '@angular/core';

import { UtilService } from './../../services/util/util.service';

import * as moment from 'moment';

@Pipe({
  name: 'clockTimerMainPipe'
})
export class ClockTimerMainPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let hours = Math.floor(moment.duration(value, 'seconds').asHours());
    let mins = moment.duration(value, 'seconds').minutes();

    return UtilService.padNumber(hours, 2, true) + ':' + UtilService.padNumber(mins, 2, false);
  }

}
