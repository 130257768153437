<div class="app-header-bar">
  <div class="app-header-bar-left">
    <div class="app-header-back"
         (click)="goBack()">
      <i class="ion ion-md-arrow-back"></i>
    </div>
  </div>
  <div class="app-header-bar-title">
    <span>History</span>
  </div>
  <div class="app-header-bar-right">
    <div class="ph-offlineHeaderTag"
         *ngIf="isOffline">
      OFFLINE
    </div>
  </div>
</div>

<ion-content>

  <div class="ph-histRecord-headerRow">
    <div class="ph-histRecord-headerDate">
      {{clockRecord.clock_in_time | date : 'd MMMM'}}
      <span *ngIf="multiDayClock">
        - {{clockRecord.clock_out_time | date : 'd MMMM'}}
      </span>
      <span>{{clockRecord.clock_out_time | date : ', yyyy'}}</span>
    </div>
    <div class="ph-histRecord-headerName">
      {{clockRecord.employee_code}}
    </div>
  </div>

  <div class="ph-histRecord-empCardContainer">

    <div class="ph-employeeCard-container">
      <div class="ph-employeeCard-imgContainer">
        <img class="ph-employeeCard-img"
             *ngIf="clockRecord.startImg"
             [src]="clockRecord.startImg">
        <i class="ion ion-ios-contact ph-employeeCard-imgPlaceholder"
           *ngIf="!clockRecord.startImg">
        </i>
      </div>

      <div class="ph-employeeCard-label">
        {{clockRecord.clock_in_time | date : 'h:mm'}}<span>{{clockRecord.clock_in_time | date : 'a' | lowercase}}</span>
      </div>
    </div>

    <div class="ph-employeeCard-container">
      <div class="ph-employeeCard-imgContainer">
        <img class="ph-employeeCard-img"
             *ngIf="clockRecord.endImg"
             [src]="clockRecord.endImg">
        <i class="ion ion-ios-contact ph-employeeCard-imgPlaceholder"
           *ngIf="!clockRecord.endImg">
        </i>
      </div>

      <div class="ph-employeeCard-label">
        {{clockRecord.clock_out_time | date : 'h:mm'}}
        <span>{{clockRecord.clock_out_time | date : 'a' | lowercase}}</span>
      </div>
    </div>

  </div>

  <div class="ph-histRecord-duration"
       *ngIf="clockRecord.segment_key">

    <div class="ph-histRecord-durationLabel">
      Duration
    </div>
    <div class="ph-histRecord-durationValue">
      {{durationSeconds | clockTimerMainPipe}}
    </div>

  </div>

  <div class="ph-cardLabel"
       *ngIf="clockRecord.clock_breaks.length">
    Breaks
  </div>
  <div class="ph-clockBreak-container"
       *ngIf="clockRecord.clock_breaks.length">

    <div class="ph-clockBreak-row"
         *ngFor="let clockBreak of clockRecord.clock_breaks">
      <div class="ph-clockBreak-time">
        {{clockBreak.start_time | date : 'shortTime'}}
      </div>
      <div class="ph-clockBreak-timeDivider">-</div>
      <div class="ph-clockBreak-time">
        {{clockBreak.end_time | date : 'shortTime'}}
      </div>

      <div class="ph-clockBreak-duration">
        {{clockBreak.break_duration | hoursToTimePipe}}
      </div>
    </div>
  </div>


  <div class="ph-cardLabel"
       *ngIf="clockRecord.clock_notes.length">
    Notes
  </div>
  <div class="ph-clockNote-container"
       *ngIf="clockRecord.clock_notes.length">
    <div class="ph-clockNote-note"
         [ngStyle]="{ 'background-color': clockRecord.clock_notes.length === 1 ? '#FFF' : '' }"
         *ngFor="let note of clockRecord.clock_notes">
      <p>{{note.note}}</p>
    </div>
  </div>


  <div class="ph-cardLabel"
       *ngIf="clockRecord.segment_key">
    Time Entry
  </div>
  <div class="ph-clockSegment-container"
       *ngIf="clockRecord.segment_key">

    <div class="ph-clockSegment-workColor"
         [ngStyle]="{ 'background-color': clockRecord.project.project_colour}">
    </div>

    <div class="ph-clockSegment-work">
      {{clockRecord.project.project_title}}
    </div>
    <div class="ph-clockSegment-duration">
      <div>{{clockRecord.duration | hoursToTimePipe}} hours</div>
      <div *ngIf="clockRecord.break_duration">
        Break: {{clockRecord.break_duration | hoursToTimePipe}}
      </div>
    </div>
    <div class="ph-clockSegment-times">
      <div>
        {{clockRecord.start_time | date : 'shortTime'}} -
        {{clockRecord.end_time | date : 'shortTime'}}
      </div>
      <div>{{clockRecord.segment_date | date: 'EEEE, d LLL'}}</div>
    </div>
  </div>


</ion-content>
