import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';

import PouchDB from 'pouchdb-browser';

@Injectable({
  providedIn: 'root'
})
export class EmployeeImageService {

  db: any;

  constructor() { }


  initDB() {
    try {
      this.db = new PouchDB("ph-droppah-clockImages", {
        adapter: "idb"
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  /**
   * Checks if an image already exists for the given employee.
   * Then either adds a new row or updates an existing row with the new image.
   * Then returns a URL referencing the image
   *
   * @param {number} employee_key
   * @param {string} imgTime - format: "YYYY-MM-DD HH:mm"
   * @param {Blob} imgBlob
   */
  setEmployeeImage(employee_key, imgTime, imgBlob) {
    return new Promise<any>((resolve, reject) => {
      // Row ID must be a string
      let _id = employee_key + "";

      this.db.get(_id)
        // Overwriting an existing doc
        .then((doc) => {
          doPut(employee_key, imgTime, imgBlob, doc._rev);
        })
        // Creating new doc
        .catch(() => {
          doPut(employee_key, imgTime, imgBlob, null);
        });

      let doPut = (employee_key, imgTime, imgBlob, rev) => {
        // Row ID must be a string
        let _id = employee_key + "";

        let data = {
          _id: _id,
          _rev: rev,
          employee_key: employee_key,
          imgTime: imgTime,
          _attachments: {
            'image': {
              content_type: "image/jpeg",
              data: imgBlob
            }
          }
        };

        this.db.put(data)
          .then(() => {
            this.getEmployeeImageUrlAndTime(employee_key)
              .then((imageData) => {
                resolve(imageData);
              });
          })
          .catch((err) => {
            reject(err);
          })
      };
    });
  }

  /**
   * Gets the current image url and image time string for a given employee, assuming one exists
   *
   * @param {number} employee_key
   */
  getEmployeeImageUrlAndTime(employee_key) {
    return new Promise<any>((resolve, reject) => {
      let promises = [
        this.getEmployeeImageUrl(employee_key),
        this.getEmployeeImageTime(employee_key)
      ];

      forkJoin(promises)
        .subscribe(
          (results) => {
            resolve({
              employee_key: employee_key,
              imgUrl: results[0],
              imgTime: results[1]
            });
          },
          (err) => {
            reject();
          }
        );
    });
  }

  /**
   * Gets the current image url for a give employee, assuming one exists
   *
   * @param {number} employee_key
   */
  getEmployeeImageUrl(employee_key) {
    return new Promise<any>((resolve, reject) => {
      // Row ID must be a string
      let _id = employee_key + "";

      this.db.getAttachment(_id, "image")
        .then(
          (blob) => {
            resolve(URL.createObjectURL(blob));
          }
        ).catch(
          () => {
            resolve(null);
          }
        );
    });
  }

  /**
   * Gets the current image time string for a given employee, assuming one exists
   *
   * @param {number} employee_key
   */
  getEmployeeImageTime(employee_key) {
    return new Promise<any>((resolve, reject) => {
      // Row ID must be a string
      let _id = employee_key + "";

      this.db.get(_id)
        .then((doc) => {
          resolve(doc.imgTime);
        })
        .catch((err) => {
          resolve(null);
        });
    });
  }

}
