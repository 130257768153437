import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Meta } from '@angular/platform-browser';

import { TeamService } from './../../../services/team/team.service';
import { CompanyService } from './../../../services/company/company.service';
import { AuthService } from './../../../services/auth/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  allTeams: any[] = [];
  selectedTeam: any;

  versionNumber: any;

  menuOpen: boolean = false;

  constructor(
    public stateService: StateService,
    public authService: AuthService,
    public companyService: CompanyService,
    public teamService: TeamService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.versionNumber = this.meta.getTag('name=appVersion').content;
  }

  goToTeamSelect() {
    this.closeMenu();
  }

  goToClocks() {
    this.closeMenu();
  }

  goToHistory() {
    this.closeMenu();
  }

  logout() {
    this.closeMenu();
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  closeMenu() {
    this.menuOpen = false;
  }

}
