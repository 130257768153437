import { Injectable } from '@angular/core';
import { TransitionService, StateService } from '@uirouter/core';

import { AuthService } from './../auth/auth.service';
import { StateAccessService } from './../state-access/state-access.service';
import { UtilService } from './../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class StateChangeService {

  constructor(
    public transitionService: TransitionService,
    public authService: AuthService,
    public stateAccessService: StateAccessService,
    public utilService: UtilService,
    public stateService: StateService
  ) { }

  initTransitionListeners() {
    this.listenForAppInit();
  }

  // Only triggered when app is refreshed.
  // Before loading state user was on before refresh,
  // first temporarily redirect them to the splash screen so the stateAccessService and
  // ensure all service data is loaded and user still has access to the previous state
  listenForAppInit() {
    this.transitionService.onStart({ from: StateChangeService.isRefreshState, to: 'app.**' }, (trans) => {

      const redirectToSplash = () => {
        if (trans.to().name !== 'splash') {

          return this.stateService.target('splash', {
            refreshStateName: trans.to().name,
            refreshStateParams: trans.params('to')
          });
        }
      }

      if (this.authService.verifyAuth()) {
        return redirectToSplash();
      }
      else {
        return this.utilService.tryTokenLogin()
          .then(() => {
            return redirectToSplash();
          })
          .catch(() => {
            if (this.utilService.isOffline && this.authService.return_login_token) {
              return redirectToSplash();
            }
            else {
              this.authService.goApplet('login');
            }
          });
      }
    });
  }

  static isRefreshState(state: any): boolean {
    return state.name === '';
  }

  static notRefreshState(state: any): boolean {
    return state.name !== '';
  }

}
