import { Injectable } from '@angular/core';

import { UtilService } from './../util/util.service';
import { CompanyService } from './../company/company.service';

import * as _ from 'lodash';
import PouchDB from 'pouchdb-browser';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  allProjects: any[];
  allTimesheetProjects: any[];
  allTimesheetHoursProjects: any[];
  allProjectsMap: any;

  db: any;

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public companyService: CompanyService
  ) { }

  initialiseService(){
    return new Promise<any>((resolve, reject) => {
      if (this.serviceSetup){
        resolve(this.allProjects);
      }
      else {
        this.loadAllProjects()
          .then(() => {
            this.serviceSetup = true;
            resolve(this.allProjects);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      }
    });
  }

  clearServiceData(clearDatabases){
    this.allProjects = null;
    this.allTimesheetProjects = null;
    this.allTimesheetHoursProjects = null;
    this.allProjectsMap = null;

    if (clearDatabases){
      this.clearProjectsFromDB();
    }
    this.serviceSetup = false;
  }

  getAllProjects(){
    return _.cloneDeep(this.allProjects);
  }

  getAllTimesheetProjects(){
    return _.cloneDeep(this.allTimesheetProjects);
  }

  getAllTimesheetHoursProjects(){
    return _.cloneDeep(this.allTimesheetHoursProjects);
  }

  getSingleProject(project_key){
    return _.cloneDeep(this.allProjectsMap[project_key]) || null;
  }

  initDB(){
    try {
      this.db = new PouchDB("ph-droppah-projects", {
        adapter: "idb"
      });
    }
    catch(err){
      console.log(err);
    }
  }

  loadProjectsIntoDB(projects){
    if (this.db){
      let dbProjects = _.cloneDeep(projects);

      for (let i = 0; i < dbProjects.length; i++){
        let project = dbProjects[i];

        // Set project database ID as their project key
        // ID needs to be a string
        project._id = project.project_key + "";
      }

      this.db.bulkDocs(dbProjects)
        .catch((err) => {
          console.log(err);
        });
    }
  }

  loadProjectsFromDB(){
    return new Promise<any>((resolve, reject) => {
      if (this.db){
        this.db.allDocs({ include_docs: true })
          .then((docs) => {
            let projects = [];

            for (let i = 0; i < docs.rows.length; i++){
              let doc = docs.rows[i].doc;

              // Remove DB related properties from project
              delete doc._id;
              delete doc._rev;

              projects.push(doc);
              resolve(projects);
            }
          })
          .catch(() => {
            reject();
          });
      }
      else {
        reject();
      }
    });
  }

  clearProjectsFromDB(){
    return new Promise<void>((resolve, reject) => {
      if (this.db) {

        this.db.destroy()
          .then(() => {
            this.initDB();
            resolve();
          })
          .catch((err) => {
            console.log(err);
            resolve();
          });
      }
      else {
        resolve();
      }
    });
  }

  loadAllProjects(){
    return new Promise((resolve, reject) => {
      let employee_key = this.companyService.getEmployeeKey();
      let params = {
        employee_key: employee_key
      };

      if (!employee_key){
        delete params.employee_key;
      }

      this.utilService.APIGet('project', params, false, null, false)
        .then((data) => {
          this.loadProjectsIntoDB(data);

          setupAllProjectArrays(data);
        })
        // Fallback to loading projects from IndexedDB
        .catch(() => {
          this.loadProjectsFromDB()
            .then((data) => {
              setupAllProjectArrays(data);
            })
            .catch(() => {
              reject();
            });
        });

      let setupAllProjectArrays = (data) => {
        this.allProjects = [];
        this.allTimesheetProjects = [];
        this.allTimesheetHoursProjects = [];
        this.allProjectsMap = {};

        for (let i = 0; i < data.length; i++){
          let project = ProjectService.setupProject(data[i]);

          this.allProjectsMap[project.project_key] = project;

          if (project.active_flag){
            this.allProjects.push(project);
          }

          if (project.pay_code_sub_type === "Regular Earnings" ||
            project.pay_code_sub_type === "Other Earnings"){

            if (project.active_flag){
              this.allTimesheetProjects.push(project);
            }
          }

          if (project.pay_code_unit === 'Hours'){

            if (project.active_flag){
              this.allTimesheetHoursProjects.push(project);
            }
          }
        }

        ProjectService.sortProjectsArray(this.allProjects);
        ProjectService.sortProjectsArray(this.allTimesheetProjects);
        ProjectService.sortProjectsArray(this.allTimesheetHoursProjects);

        resolve(this.allProjects);
      }
    });
  }



  /**
   * Sorts an array of projects by project_title
   *
   * @param {Array<Object>} projects
   * @returns {Array<Object>}
   */
  static sortProjectsArray(projects){
    projects.sort((a, b) => {
      if (a.project_title > b.project_title){
        return 1;
      }
      else if (a.project_title < b.project_title){
        return -1;
      }
      return 0;
    });

    return projects;
  }

  /**
   * Sets up non primitive project properties
   *
   * @param {Object} project
   * @returns {Object} project
   */
  static setupProject(project){
    project.project_colour = UtilService.intToHexColor(project.project_colour);

    if (project.default_start_time){
      project.default_start_time = UtilService.hoursMinsStringToDate(project.default_start_time);
    }
    if (project.default_end_time){
      project.default_end_time = UtilService.hoursMinsStringToDate(project.default_end_time);
    }

    return project;
  }

}
