<div class="app-header-bar">
  <div class="app-header-bar-left">
    <div class="app-header-back"
         (click)="goBack()">
      <i class="ion ion-md-arrow-back"></i>
    </div>
  </div>
  <div class="app-header-bar-title">
    <span>Clock In</span>
  </div>
  <div class="app-header-bar-right">
    <div class="ph-offlineHeaderTag"
         *ngIf="isOffline">
      OFFLINE
    </div>
  </div>
</div>

<ion-content>

  <div class="ph-employeeList-filterRow">
    <ion-input [(ngModel)]="employeeSearch"
               (ngModelChange)="filterEmployees()"
               [disabled]="loadingEmployees"
               type="text"
               placeholder="Search Employees...">
    </ion-input>
  </div>

  <div class="ph-emptyMessage"
       *ngIf="visibleEmployeeCount === 0">
    <i class="ion ion-ios-people"></i>
    <div>No Employees Found</div>
  </div>

  <div class="ph-employeeList-container">

    <div class="ph-employeeCard-container"
         [hidden]="!emp.visible"
         *ngFor="let emp of inactiveEmployees"
         (click)="clockIn(emp)">

      <div class="ph-employeeCard-imgContainer">

        <img class="ph-employeeCard-img"
             *ngIf="emp.imgUrl"
             [src]="emp.imgUrl">
        <i class="ion ion-ios-contact ph-employeeCard-imgPlaceholder"
           *ngIf="!emp.imgUrl">
        </i>

      </div>

      <div class="ph-employeeCard-label">
        {{emp.employee_code}}
      </div>
    </div>

  </div>

  <div class="ph-spinner"
       *ngIf="loadingEmployees">
    <ion-spinner></ion-spinner>
  </div>

</ion-content>
