import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { StateRegistry, StateService } from '@uirouter/core';

import { ClockQueueService } from './../clock-queue/clock-queue.service';
import { HistoryImageService } from './../history-image/history-image.service';
import { EmployeeImageService } from './../employee-image/employee-image.service';
import { CompanyDbService } from './../company-db/company-db.service';
import { CompanyService } from './../company/company.service';
import { TeamService } from './../team/team.service';
import { ClockService } from './../clock/clock.service';
import { EmployeeService } from './../employee/employee.service';
import { ProjectService } from './../project/project.service';

@Injectable({
  providedIn: 'root'
})
export class StateAccessService {

  constructor(
    public projectService: ProjectService,
    public employeeService: EmployeeService,
    public clockService: ClockService,
    public teamService: TeamService,
    public companyService: CompanyService,
    public companyDBService: CompanyDbService,
    public employeeImageService: EmployeeImageService,
    public historyImageService: HistoryImageService,
    public clockQueueService: ClockQueueService,
    public stateRegistry: StateRegistry,
    public stateService: StateService
  ) { }

  reloadStateAccess(refreshStateName: string = null, refreshStateParams: any = null) {
    this.goToStateAfterReload('app.clock.view', {}, refreshStateName, refreshStateParams);
  }

  goToStateAfterReload(defaultState: string, defaultStateParams: any, refreshStateName: string = null, refreshStateParams: any = null) {

    if (refreshStateName !== null && this.stateRegistry.get(refreshStateName)) {
      this.stateService.go(refreshStateName, refreshStateParams);
    }
    else {
      this.stateService.go(defaultState, defaultStateParams);
    }
  }

  ensureServicesInitialised() {
    return new Promise<void>((resolve, reject) => {

      this.companyDBService.initDB();
      this.employeeImageService.initDB();
      this.historyImageService.initDB();
      this.clockQueueService.initDB();
      this.employeeService.initDB();
      this.clockService.initDB();
      this.teamService.initDB();
      this.projectService.initDB();


      this.companyService.initialiseService()
        .then(() => {

          this.teamService.initialiseService()
            .then(() => {

              forkJoin([
                this.projectService.initialiseService(),
                this.employeeService.initialiseService()
              ]).subscribe(
                () => {
                  resolve();
                },
                (err) => {
                  reject(err);
                }
              );
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });

    });
  }

  clearServiceData(clearDatabases: boolean = false) {
    this.companyService.clearServiceData();
    this.companyDBService.clearServiceData(clearDatabases);
    this.employeeService.clearServiceData(clearDatabases);
    this.projectService.clearServiceData(clearDatabases);
    this.clockService.clearServiceData(clearDatabases);
    this.teamService.clearServiceData(clearDatabases);
  }
}
