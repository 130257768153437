import { forkJoin } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

import { UtilService } from './../util/util.service';

import PouchDB from 'pouchdb-browser';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HistoryImageService {

  db: any;

  dateHistoryWasCleared: Date = null;
  maxHistoryImages = 1500;

  constructor(
    private domSanitizer: DomSanitizer
  ) { }


  initDB() {
    try {
      this.db = new PouchDB("ph-droppah-historyImages", {
        adapter: "idb"
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  setupHistoryImages(history) {
    return new Promise<any>((resolve, reject) => {
      let imagePromises = [];

      for (const clock of history) {
        let startString = UtilService.dateToDateTimeString(clock.clock_in_time, null);
        let endString = UtilService.dateToDateTimeString(clock.clock_out_time, null);

        imagePromises.push(this.getHistoryImageUrl(clock.employee_key, startString));
        imagePromises.push(this.getHistoryImageUrl(clock.employee_key, endString));
      }

      if (imagePromises.length) {
        forkJoin(imagePromises)
          .subscribe(
            (historyImageData) => {
              let imageMap = {};

              // Generate a history image URL map from the result the async calls to get image urls
              for (const data of historyImageData) {
                if (data) {
                  let key = data["employee_key"] + "_" + data["imgTime"];

                  imageMap[key] = data;
                }
              }

              // For each clockRecord, assign it's start and end imgUrls from the history URL map we just created
              for (const clock of history) {
                let startKey = clock.employee_key + "_" + UtilService.dateToDateTimeString(clock.clock_in_time, null);
                let endKey = clock.employee_key + "_" + UtilService.dateToDateTimeString(clock.clock_out_time, null);

                clock.startImg = imageMap[startKey] ? this.domSanitizer.bypassSecurityTrustResourceUrl(imageMap[startKey].imgUrl) : null;
                clock.endImg = imageMap[endKey] ? this.domSanitizer.bypassSecurityTrustResourceUrl(imageMap[endKey].imgUrl) : null;
              }
              resolve(history);
            },
            () => {
              reject();
            }
          );
      }
      else {
        resolve(history);
      }
    });
  }

  clearOldHistory() {
    let currentTime = new Date();
    let dayInMilliseconds = 24 * 60 * 60 * 1000;

    // If history hasn't yet been cleared or was last cleared over 24 hours ago
    if (this.db && (!this.dateHistoryWasCleared || currentTime.valueOf() > (this.dateHistoryWasCleared.valueOf() + dayInMilliseconds))) {

      this.getAllHistory()
        .then((history) => {

          if (history.length > this.maxHistoryImages) {
            let sortedHistory = [];
            let numImagesToDelete = history.length - this.maxHistoryImages;

            for (let i = 0; i < history.length; i++) {
              let hist = history[i];
              let histDate = HistoryImageService.getHistoryImageDate(hist.id);

              sortedHistory.push({
                _id: hist.doc._id,
                _rev: hist.doc._rev,
                _deleted: false,
                date: histDate,
                dateString: histDate.valueOf()
              });
            }

            // Sort oldest first
            sortedHistory.sort((a, b) => {
              if (a.dateString > b.dateString) {
                return 1
              }
              if (a.dateString < b.dateString) {
                return -1;
              }
              return 0;
            });

            let historyToDelete = sortedHistory.slice(0, numImagesToDelete);

            for (let i = 0; i < historyToDelete.length; i++) {
              historyToDelete[i]._deleted = true;
            }

            this.db.bulkDocs(historyToDelete)
              .then(() => { })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  static getHistoryImageDate(historyImageKey) {
    let dateString = historyImageKey.substring(historyImageKey.indexOf("_") + 1);
    return moment(dateString).toDate();
  }

  getAllHistory() {
    return new Promise<any>((resolve, reject) => {
      return this.db.allDocs({ include_docs: true })
        .then((doc) => {
          resolve(doc.rows);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setHistoryImage(employee_key, imgTime, imgBlob) {
    return new Promise<void>((resolve, reject) => {
      // Row ID must be a string
      let _id = employee_key + "_" + imgTime;

      this.db.get(_id)
        // Overwriting an existing doc
        .then((doc) => {
          this.doPut(employee_key, imgTime, imgBlob, doc._rev)
            .then(() => { resolve(); })
            .catch(() => { reject() });
        })
        // Creating new doc
        .catch(() => {
          this.doPut(employee_key, imgTime, imgBlob, null)
            .then(() => { resolve(); })
            .catch(() => { reject() });
        });
    });
  }

  doPut(employee_key, imgTime, imgBlob, rev) {
    return new Promise<void>((resolve, reject) => {
      // Row ID must be a string
      let _id = employee_key + "_" + imgTime;

      let data = {
        _id: _id,
        _rev: rev,
        _attachments: {
          'image': {
            content_type: "image/jpeg",
            data: imgBlob
          }
        }
      };

      this.db.put(data)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  getHistoryImageUrl(employee_key, imgTime) {
    return new Promise<any>((resolve, reject) => {
      // Row ID must be a string
      let _id = employee_key + "_" + imgTime;

      this.db.getAttachment(_id, "image")
        .then(
          (blob) => {
            resolve({
              employee_key: employee_key,
              imgTime: imgTime,
              imgUrl: URL.createObjectURL(blob)
            });
          }
        ).catch(
          () => {
            resolve(null);
          }
        );
    });
  }


}
