import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StateDataService, Auth } from './../state-data/state-data.service';
import { UtilService } from './../util/util.service';

import { env } from '../../../environments/environment';

declare var Base64;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _auth: Auth = {
    session_key: null,
    company_code: null,
    return_login_token: null,
    return_session_key: null,
    user_access_company_key: null
  };

  private readonly _trace: string = AuthService.generateUUID();

  constructor(
    public stateDataService: StateDataService,
    public http: HttpClient
  ) {
    this._auth = this.stateDataService.auth;
  }

  //////////////////////////////////////////

  goApplet(state: string, params: any = null, new_tab: boolean = false) {
    let url = env.subscription.app_url;
    if (state === 'login') {
      new_tab = false;
      if (params) {
        url += 'login?destination=PAYHERO_SHIFT&' + new URLSearchParams(params).toString();
      }
      else {
        url += 'login?destination=PAYHERO_SHIFT';
      }
    }
    else {
      url += 'loginExternal?urlParams=' + Base64.encode(
        JSON.stringify({
          session_key: this._auth.return_session_key,
          state,
          params
        })
      );
    }

    if (!new_tab) {
      window.location.href = url;
    }
    else {
      window.open(url, '_blank');
    }
  }

  externalLogin(session_key: string, company_code: string, user_access_company_key: number, return_session_key: string) {
    this._auth.company_code = company_code;
    this._auth.user_access_company_key = user_access_company_key;
    this._auth.session_key = session_key;
    this._auth.return_session_key = return_session_key;

    this.cacheAuth();
  }

  loginOffline() {
    return new Promise<void>((resolve, reject) => {
      if (
        this._auth &&
        this._auth.return_login_token &&
        this._auth.company_code
      ) {
        resolve();
      }
      else {
        reject();
      }
    });
  }

  logout(manual_logout: boolean = false) {
    this._auth = {
      session_key: null,
      return_login_token: null,
      company_code: null,
      user_access_company_key: null,
      return_session_key: null
    };
    this.stateDataService.auth = null; // Clear auth

    if (manual_logout) {
      this.stateDataService.clearAllCachedData();
    }
  }

  //////////////////////////////////////////

  verifyAuth(): boolean {
    return !!this._auth && !!this._auth.session_key && !!this._auth.company_code;
  }

  cacheAuth() {
    this.stateDataService.auth = this._auth;
  }

  clearCachedAuth() {
    this.stateDataService.auth = null;
  }

  //////////////////////////////////////////

  get return_login_token(): string {
    return this._auth.return_login_token || null;
  }

  set return_login_token(value: string) {
    this._auth.return_login_token = value;
    this.cacheAuth();
  }

  get return_session_key(): string {
    return this._auth.return_session_key;
  }

  /////////////////////////////////////////

  getHTTPHeader(sessionAuth: boolean, subSessionAuth: boolean = false): any {
    if (
      (sessionAuth && !!this._auth.session_key) ||
      (!sessionAuth && !!this._auth.session_key && !!this._auth.company_code)
    ) {
      const httpHeader: any = {
        trace: this._trace
      };

      if (sessionAuth) {
        httpHeader.Authorization = 'Basic ' + Base64.encode(this._auth.session_key);
      }
      else {
        httpHeader.Authorization = 'Basic ' + Base64.encode(this._auth.company_code + ':' + this._auth.session_key);
      }

      if (subSessionAuth) {
        httpHeader.sub_session_key = Base64.encode(this._auth.return_session_key);
      }

      return httpHeader;
    }
    else {
      return null;
    }
  }

  /**
   * Generates a unique ID for use with Google login APIs
   *
   * @returns {string}
   */
  static generateUUID() {
    let d = new Date().getTime();

    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

    return uuid;
  }

  //////////////////////////////////////////

  tokenLogin(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const data = {
        login_source: env.login_source,
        login_token: this._auth.return_login_token
      };

      this.http
        .post(env.subscription.api_url + 'user_access/login', data)
        .toPromise()
        .then((res: any) => {
          const sub_session_key = res.session_key;
          const company_product_key = UtilService.parseJSON(res.additional_data).company_product_key;

          this._startNewSession(sub_session_key, company_product_key)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        })
    });
  }

  private _startNewSession(sub_session_key: string, company_product_key: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const options = {
        headers: {
          Authorization: 'Basic ' + Base64.encode(sub_session_key),
          trace: this._trace
        }
      };
      const data = {
        company_product_key
      };

      this.http
        .post(env.subscription.api_url + 'company_product/new_session', data, options)
        .toPromise()
        .then((res: any) => {

          this.externalLogin(
            res.session_key,
            res.external_company_reference,
            res.external_user_access_company_key,
            sub_session_key
          );
          resolve();
        })
        .catch(() => {
          reject();
        })
    });
  }

}
