import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClockTimerMainPipe } from './clock-timer-main/clock-timer-main.pipe';
import { ClockTimerSecondsPipe } from './clock-timer-seconds/clock-timer-seconds.pipe';
import { HoursToTimePipe } from './hours-to-time/hours-to-time.pipe';
import { MinsToTimePipe } from './min-to-time/mins-to-time.pipe';

@NgModule({
  declarations: [
    ClockTimerMainPipe,
    ClockTimerSecondsPipe,
    HoursToTimePipe,
    MinsToTimePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClockTimerMainPipe,
    ClockTimerSecondsPipe,
    HoursToTimePipe,
    MinsToTimePipe
  ]
})
export class PipesModule { }
