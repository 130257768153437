import { Injectable } from '@angular/core';

import { UtilService } from './../util/util.service';

import * as _ from 'lodash';
import PouchDB from 'pouchdb-browser';

@Injectable({
  providedIn: 'root'
})
export class ClockQueueService {

  db: any;

  constructor(
    public utilService: UtilService
  ) { }

  initDB() {
    try {
      this.db = new PouchDB("ph-droppah-clockQueue", {
        adapter: "idb"
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  queueIsEmpty(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.db.allDocs({
        include_docs: true
      })
        .then((docs) => {
          resolve(docs.rows.length === 0);
        })
        .catch(() => {
          resolve(false)
        });
    });
  }

  addToQueue(clockData) {
    return new Promise<void>((resolve, reject) => {

      // Clock In
      if (clockData.clock_in_time) {
        clockData.event_time = _.cloneDeep(clockData.clock_in_time);
        clockData.event_type = "clockin";
      }
      // Clock Out
      else if (clockData.clock_out_time) {
        clockData.event_time = _.cloneDeep(clockData.clock_out_time);
        clockData.event_type = "clockout";
      }
      // Break In
      else if (clockData.start_time) {
        clockData.event_time = _.cloneDeep(clockData.start_time);
        clockData.event_type = "breakin";
      }
      // Break Out
      else if (clockData.end_time) {
        clockData.event_time = _.cloneDeep(clockData.end_time);
        clockData.event_type = "breakout";
      }
      // Note
      else {
        clockData.event_time = _.cloneDeep(clockData.updated_time);
        clockData.event_type = "note";
      }

      this.db.post(clockData)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  flushQueue() {
    return new Promise<void>((resolve, reject) => {
      this.db.allDocs({
        include_docs: true
      }).then(
        (docs) => {

          if (docs.rows.length) {
            let clockEvents = [];
            for (let i = 0; i < docs.rows.length; i++) {
              clockEvents.push(docs.rows[i].doc);
            }

            this.postClockBatch(clockEvents)
              .then(() => {

                this.clearClockQueue()
                  .then(() => {
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              })
              .catch((err) => {
                reject();
              });
          }
          else {
            resolve();
          }
        }
      ).catch(
        (err) => {
          reject();
        }
      );
    });
  }

  postClockBatch(clockData) {
    return new Promise<void>((resolve, reject) => {

      let data = {
        clock_events: clockData
      };

      this.utilService.APIPost("clock/batch", data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  clearClockQueue() {
    return new Promise<void>((resolve, reject) => {
      this.db.destroy()
        .then(() => {
          this.initDB();
          resolve();
        });
    });
  }

}
