import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { IonicModule } from '@ionic/angular';

import { PipesModule } from './../../pipes/pipes.module';

import { NavComponent } from './nav/nav.component';
import { TeamSelectComponent } from './team-select/team-select.component';
import { SplashComponent } from './splash/splash.component';

export const CORE_CONFIG: any[] = [
  {
    name: 'app',
    abstract: true,
    component: NavComponent,
  },
  {
    name: 'app.teamSelect',
    url: '/teamSelect',
    component: TeamSelectComponent
  },
  {
    name: 'splash',
    url: '/splash',
    component: SplashComponent,
    params: {
      refreshStateName: null,
      refreshStateParams: null
    }
  }
];

@NgModule({
  declarations: [
    NavComponent,
    TeamSelectComponent,
    SplashComponent
  ],
  imports: [
    PipesModule,
    IonicModule,
    FormsModule,
    CommonModule,
    UIRouterModule.forChild({ states: CORE_CONFIG })
  ]
})
export class CoreModule { }
