<div class="app-header-bar">
  <div class="app-header-bar-title">
    <span>History</span>
  </div>
  <div class="app-header-bar-right">
    <div class="ph-offlineHeaderTag"
         *ngIf="isOffline">
      OFFLINE
    </div>
    <div class="ph-menuBtn"
         (click)="toggleMenu()">
      <img class="ph-companyLogo"
           alt=""
           [src]="currentCompany.company_logo">
      <i class="ion ion-md-arrow-dropdown ph-menuIcon">
      </i>
    </div>
  </div>
</div>

<div class="ph-menu-container"
     [ngClass]="{ 'ph-menu-containerClosed': !menuOpen }">

  <button class="ph-menu-greyButton"
          (click)="goToTeamSelect()">
    <span>{{selectedTeam ? selectedTeam.team_name : 'All Employees'}}</span>
    <i class="ion ion-md-swap"
       *ngIf="multipleCompanyTeams">
    </i>
  </button>

  <button (click)="goToClockView()">
    <span>Clock Entries</span>
  </button>

  <button>
    <span>Time History</span>
  </button>

  <div class="ph-menu-break"></div>

  <button class="ph-logoutBtn"
          (click)="logout()">
    <span>Sign Out</span>
  </button>

  <div class="ph-menu-appVersionRow">
    v{{appVersion}}
  </div>

</div>


<div id="ph-history-monthSlider"
     #monthSliderElement
     [ngStyle]="{ 'opacity': isOffline ? 0 : 1 }">
  <div id="ph-history-monthSliderInner">

    <div class="ph-history-monthSliderDay"
         *ngFor="let day of monthSlider; let i = index"
         (click)="selectDayInMonthSlider(i)">

      <div class="ph-history-monthSliderDayInner"
           [ngClass]="{ 'monthSliderDaySelected': day.selected }">
        <div class="sliderDay">
          {{day.dayLabel}}
        </div>
        <div class="sliderDate">
          {{day.dateLabel}}
        </div>
      </div>
    </div>
  </div>
</div>

<ion-content (click)="closeMenuOnBackgroundClick()">

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshDaysClockRecords($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="ph-emptyMessage"
       *ngIf="isOffline">
    <i class="ion ion-ios-time">
    </i>
    <div>History unavailable when offline</div>
  </div>

  <div class="ph-history-dayContainer"
       *ngIf="selectedDay && !isOffline">

    <div class="ph-spinner"
         *ngIf="loadingDay">
      <ion-spinner></ion-spinner>
    </div>

    <div class="ph-emptyMessage ph-history-noClockRecords"
         *ngIf="!loadingDay && !selectedDay.clockRecords.length">
      <i class="ion ion-ios-time">
      </i>
      <div>No Time Today</div>
    </div>

    <div class="ph-history-dayClockRecord"
         *ngFor="let clockRecord of selectedDay.clockRecords"
         (click)="viewHistoryRecord(clockRecord)">
      <div class="ph-history-dayClockPhotos">

        <img [src]="clockRecord.startImg"
             *ngIf="clockRecord.startImg">

        <i class="ion ion-ios-contact"
           *ngIf="!clockRecord.startImg">
        </i>

        <img [src]="clockRecord.endImg"
             *ngIf="clockRecord.endImg">

        <i class="ion ion-ios-contact"
           *ngIf="!clockRecord.endImg">
        </i>

      </div>

      <div class="ph-history-dayClockRecordDetails">
        <div class="ph-history-dayClockRecordName">
          {{clockRecord.employee_code}}
        </div>
        <div class="ph-history-dayClockRecordWork">
          <div class="ph-history-dayClockRecordWorkColour"
               *ngIf="clockRecord.project"
               [ngStyle]="{ 'background-color': clockRecord.project.project_colour }">
          </div>
          <div class="ph-history-dayClockRecordWorkName">
            {{clockRecord.project_title}}
          </div>
        </div>
      </div>

      <div class="ph-history-dayClockRecordTimes">
        <div>
          <div class="ph-history-dayClockRecordTime">
            <div>Clocked In:</div>
            <div>{{clockRecord.clock_in_time | date: 'hh:mm a'}}</div>
          </div>
          <div class="ph-history-dayClockRecordTime">
            <div>Clocked Out:</div>
            <div>{{clockRecord.clock_out_time | date: 'hh:mm a'}}</div>
          </div>
        </div>
      </div>

    </div>
  </div>

</ion-content>
