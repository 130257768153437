import { StateService } from '@uirouter/core';
import { forkJoin } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { StateAccessService } from './../../../services/state-access/state-access.service';
import { TeamService } from './../../../services/team/team.service';
import { AuthService } from './../../../services/auth/auth.service';
import { CompanyService } from './../../../services/company/company.service';
import { HistoryImageService } from './../../../services/history-image/history-image.service';
import { PinService } from './../../../services/pin/pin.service';
import { UtilService } from './../../../services/util/util.service';
import { ClockService } from './../../../services/clock/clock.service';
import { EmployeeService } from './../../../services/employee/employee.service';

@Component({
  selector: 'app-clock-view',
  templateUrl: './clock-view.component.html',
  styleUrls: ['./clock-view.component.scss']
})
export class ClockViewComponent implements OnInit {

  menuOpen: boolean = false;
  clockedEmployees: any[] = [];
  inactiveEmployees: any = [] = [];

  initialEmployeeLoad: boolean = false;
  loadingEmployees: boolean = true;

  selectedTeam: any;

  currentCompany: any;
  appVersion: string;

  multipleCompanyTeams: boolean;
  isOffline: boolean;

  clockingEmployee: boolean = false;

  constructor(
    public employeeService: EmployeeService,
    public clockService: ClockService,
    public modalCtrl: ModalController,
    public utilService: UtilService,
    public pinService: PinService,
    public historyImageService: HistoryImageService,
    public companyService: CompanyService,
    public authService: AuthService,
    public teamService: TeamService,
    public stateAccessService: StateAccessService,
    public stateService: StateService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.historyImageService.clearOldHistory();
    this.subscribeToConnectionEvents();
    this.initPage();
  }

  closeMenuOnBackgroundClick() {
    this.menuOpen = false;
  }

  initPage() {
    this.setupEmployeeClockRecords();

    this.currentCompany = this.companyService.getCompany();
    this.multipleCompanyTeams = this.teamService.hasMultipleTeams();

    this.appVersion = this.meta.getTag('name=appVersion').content;
    this.selectedTeam = this.teamService.getSelectedTeam();
  }

  logout() {
    this.authService.logout(true);
    this.authService.goApplet('login');
  }

  goToTimeHistory() {
    this.stateService.go('app.history.dash');
  }

  goToTeamSelect() {
    if (this.multipleCompanyTeams) {
      this.stateService.go('app.teamSelect');
    }
  }

  setupEmployeeClockRecords() {
    this.loadingEmployees = true;

    this.employeeService.getGroupedClockEmployees()
      .then((groupedClockEmployees) => {
        this.clockedEmployees = groupedClockEmployees[0];
        this.inactiveEmployees = groupedClockEmployees[1];

        this.loadingEmployees = false;
        this.initialEmployeeLoad = true;
      })
      .catch(() => {
        this.loadingEmployees = false;
      });
  }

  refreshEmployeeClockRecords(event) {
    this.loadingEmployees = true;

    forkJoin([
      this.employeeService.loadEmployees(),
      this.teamService.loadEmployeeTeams()
    ]).subscribe(
      () => {

        this.employeeService.getGroupedClockEmployees()
          .then((groupedClockEmployees) => {
            this.clockedEmployees = groupedClockEmployees[0];
            this.inactiveEmployees = groupedClockEmployees[1];

            this.loadingEmployees = false;
            event?.target?.complete();
          })
          .catch(() => {
            this.loadingEmployees = false;
            event?.target?.complete();
          });

      },
      () => {
        this.loadingEmployees = false;
        event?.target?.complete();
      }
    );
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  clockIn(employee) {
    this.stateService.go('app.clock.camera', { employee });
  }

  clockOut(employee) {
    this.stateService.go('app.clock.out', { clockEmployee: employee });
  }

  breakOut(employee) {
    let currentBreakFound = false;

    for (let i = 0; i < employee.clockRecord.clock_breaks.length; i++) {
      let clockBreak = employee.clockRecord.clock_breaks[i];

      if (!clockBreak.end_time) {
        currentBreakFound = true;
        break;
      }
    }

    if (currentBreakFound) {

      if (!this.clockingEmployee) {
        this.clockingEmployee = true;

        this.employeeService.breakOutEmployee(employee)
          .then(() => {
            this.setupEmployeeClockRecords();
            this.clockingEmployee = false;
          })
          .catch(() => {
            this.setupEmployeeClockRecords();
            this.clockingEmployee = false;
          });
      }
    }
  }

  viewEmployeeList() {
    this.stateService.go('app.clock.employee');
  }

  subscribeToConnectionEvents() {
    this.isOffline = this.utilService.isOffline;

    this.utilService.getPhOnlineEvent().subscribe((isOnline) => {
      this.isOffline = !isOnline;
    });
  }

}
