import { Meta } from '@angular/platform-browser';
import { Injectable, Injector, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingController, ToastController } from '@ionic/angular';
import { timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService } from './../auth/auth.service';

import * as moment from 'moment';
import * as _ from 'lodash';
import * as EXIF from "exif-js";
import { env } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public readonly api_url: string = env.payhero.api_url;

  localStorage: any = window.localStorage;
  sessionStorage: any = window.sessionStorage;

  public header: any = null;
  public loadingSpinner: any = null;
  public isOffline: boolean = true;
  public updatingLoginStatus: boolean = false;

  public errorToast: any = null;

  public cameraSupported: boolean = null;

  appVersion: any;

  blobQuality: number = 0.5;

  public phOnlineEvent$: EventEmitter<boolean>;

  constructor(
    private meta: Meta,
    public http: HttpClient,
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    public injector: Injector,
    public authService: AuthService
  ) {
    this.phOnlineEvent$ = new EventEmitter();
    this.appVersion = this.meta.getTag('name=appVersion').content;
  }

  getPhOnlineEvent(): Observable<boolean> {
    return this.phOnlineEvent$.asObservable();
  }

  setCameraSupport(isSupported) {
    this.cameraSupported = isSupported;
  }

  cameraIsSupported() {
    return this.cameraSupported;
  }

  setHttpHeader(header) {
    this.header = header;
  }

  tryTokenLogin(bypassTryAPI: boolean = false) {
    return new Promise<void>((resolve, reject) => {
      if (!!this.authService.return_login_token) {
        if (bypassTryAPI) {
          this.authService.tokenLogin()
            .then(() => {
              this.loadNewReturnLoginToken().catch(() => {});
              resolve();
            })
            .catch(() => {
              reject();
            });
        }
        else {
          this.tryAPI()
            .then(() => {
              this.authService.tokenLogin()
                .then(() => {
                  this.loadNewReturnLoginToken().catch(() => {});
                  resolve();
                })
                .catch(() => {
                  reject();
                });
            })
            .catch(() => {
              // Valid token but is offline
              reject();
            });
        }
      }
      else {
        // No token
        reject();
      }
    });
  }

  tryAPI() {
    return new Promise<void>((resolve, reject) => {

      this.http
        .get(this.api_url + "hello/shift")
        .pipe(timeout(5000))
        .toPromise()
        .then((data: any) => {
          this.checkForAppUpdates(data.min_shift_version);

          this.isOffline = false;
          this.phOnlineEvent$.emit(true);

          resolve();
        })
        .catch((err) => {
          this.isOffline = true;
          this.phOnlineEvent$.emit(false);
          reject(err);
        });
    });
  }

  APIPost(
    URL: string,
    data: any,
    hideError: boolean = false,
    bypassTryAPI: boolean = false,
    sessionAuth: boolean = false,
    subSessionAuth: boolean = false,
  ) {
    return new Promise<any>((resolve, reject) => {

      let makeHttpCall = () => {
        let options = {
          headers: this.authService.getHTTPHeader(sessionAuth, subSessionAuth)
        };

        this.http
          .post(this.api_url + URL, data, options)
          .toPromise()
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            // Successfully reached API but was unauthorised
            // Calls that only require sessionAuth should not be reattempted
            if (error?.status === 401 && !sessionAuth) {
              this.tryTokenLogin(true);
            }
            else {
              reject(this.processAPIError(error, hideError, URL));
            }
          });
      };

      if (bypassTryAPI) {
        makeHttpCall();
      }
      else {
        this.tryAPI()
          .then(() => {
            makeHttpCall();
          })
          .catch(() => {
            reject({ message: "OFFLINE", data: null })
          });
      }
    });
  }

  APIGet(URL, params, hideError: boolean = false, responseType: any = null, sessionAuth: boolean = false) {
    return new Promise<any>((resolve, reject) => {

      this.tryAPI()
        .then(() => {
          let options = {
            headers: this.authService.getHTTPHeader(sessionAuth),
            params: params,
            responseType: responseType
          };

          // Only need to set as arraybuffer when downloading PDF
          if (!responseType) {
            delete options.responseType;
          }

          this.http
            .get(this.api_url + URL, options)
            .toPromise()
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              // Successfully reached API but was unauthorised
              // Calls that only require sessionAuth should not be reattempted
              if (error?.status === 401 && !sessionAuth) {
                this.tryTokenLogin(true);
              }
              reject(this.processAPIError(error, hideError, URL));
            });
        })
        .catch(() => {
          reject({ message: "OFFLINE", data: null })
        });
    });
  }

  private processAPIError(err: any, hideError: boolean, URL: string) {
    console.log(err);
    let res;

    if (err && err.error && typeof err.error === 'string') {
      res = {
        message: err.error,
        data: err
      }
    }
    else {
      res = {
        message: "We encountered an issue. If this continues to happen please, contact support.",
        data: err
      }
    }

    if (!hideError && res.message) {
      this.toastMessage(res.message);
    }

    return res;
  }

  loadNewReturnLoginToken(): Promise<void> {
    console.log('loadNewReturnLoginToken');
    return new Promise<void>((resolve, reject) => {
      this.APIPost('useraccesscompany/token', null, false, false, false, true)
        .then((result) => {
          if (result?.return_login_token) {
            this.authService.return_login_token = result.return_login_token;
            resolve();
          }
          else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async toastMessage(message) {
    if (this.errorToast) {
      return;
    }

    this.errorToast = await this.toastCtrl.create({
      message: message || "Oops! We encountered an issue. If this continues to happen, please contact support.",
      duration: 5000,
      position: 'top',
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => { }
        }
      ]
    });

    this.errorToast.present();

    this.errorToast.onDidDismiss().then(() => {
      this.errorToast = null;
    });

  }

  // SessionStorage & LocalStorage /////////////////////////////////

  getLocalStorageData(dataName) {
    let data = this.localStorage.getItem(dataName);
    return data ? UtilService.parseJSON(data) : null;
  }

  setLocalStorageData(dataName, data) {
    this.localStorage.setItem(dataName, JSON.stringify(data));
  }

  clearLocalStorageData(dataName) {
    this.localStorage.removeItem(dataName);
  }

  getSessionStorageData(dataName) {
    let data = this.sessionStorage.getItem(dataName);
    return data ? UtilService.parseJSON(data) : null;
  }

  setSessionStorageData(dataName, data) {
    this.sessionStorage.setItem(dataName, JSON.stringify(data));
  }

  clearSessionStorageData(dataName) {
    this.sessionStorage.removeItem(dataName);
  }

  // Auto App Update Logic /////////////////////////////////////////

  cacheLastAutoUpdateAppVersions(existing_version, min_shift_version) {
    let data = {
      existing_version,
      min_shift_version
    };
    this.localStorage.setItem("ph_shift_lastAutoUpdateVersions", JSON.stringify(data));
  }

  getCachedLastAutoUpdateAppVersions() {
    let data = this.localStorage.getItem("ph_shift_lastAutoUpdateVersions");
    return data ? UtilService.parseJSON(data) : null;
  }

  appBelowMinVersion(min_shift_version) {
    if (!min_shift_version) {
      return true;
    }

    let latestSubVersions = min_shift_version.split(".");
    let latestMajor = parseInt(latestSubVersions[0]);
    let latestMinor = parseInt(latestSubVersions[1]);
    let latestPatch = parseInt(latestSubVersions[2]);

    let existingSubVersions = this.appVersion.split(".");
    let existingMajor = parseInt(existingSubVersions[0]);
    let existingMinor = parseInt(existingSubVersions[1]);
    let existingPatch = parseInt(existingSubVersions[2]);

    if (existingMajor < latestMajor) {
      return true;
    }
    else {
      if (existingMinor < latestMinor) {
        return true;
      }
      else {
        if (existingPatch < latestPatch) {
          return true;
        }
        else {
          return false;
        }
      }
    }
  }

  /**
   * Check the current app version against the min_shift_version we get back from the hello API
   * If the current version is lower than the min_shift_version &
   * we haven't already tried to auto update on the current version,
   * then refresh the app to attempt to reload with the latest src files
   *
   * @param min_shift_version
   */
  checkForAppUpdates(min_shift_version) {
    if (this.appBelowMinVersion(min_shift_version)) {
      let lastAutoUpdateAppVersions = this.getCachedLastAutoUpdateAppVersions();

      // If we've checked for updates before, make sure we're not trying to reload
      // if we've already tried to auto update on the current version
      if (lastAutoUpdateAppVersions !== null) {
        let lastCachedExistingVersion = lastAutoUpdateAppVersions.existing_version;
        let lastCachedMinVersion = lastAutoUpdateAppVersions.min_shift_version;

        // Only try to auto update once for each this.appVersion/min_shift_version pair
        if (this.appVersion !== lastCachedExistingVersion ||
          min_shift_version !== lastCachedMinVersion) {

          this.cacheLastAutoUpdateAppVersions(this.appVersion, min_shift_version);
          location.reload(true);
        }
      }
      // No lastAutoUpdateAppVersions means we haven't checked for updates before
      else {
        this.cacheLastAutoUpdateAppVersions(this.appVersion, min_shift_version);
        location.reload(true);
      }
    }
  }

  // Blob Processing ///////////////////////////////////////////////

  /**
   * Checks whether browser has a toBlob method for the canvas object.
   * If it doesn't then it will create its own method to read in uploaded images.
   */
  checkCanvasToBlobCompatibility() {
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
          var canvas = this;
          setTimeout(function () {

            var binString = atob(canvas.toDataURL(type, quality).split(',')[1]),
              len = binString.length,
              array = new Uint8Array(len);

            for (var i = 0; i < len; i++) {
              array[i] = binString.charCodeAt(i);
            }

            callback(new Blob([array], { type: type || 'image/jpeg' }));

          });
        }
      });
    }
  }

  /**
   * Generates a blob image from an HTML video element
   *
   * @param {HTML Element} video
   */
  generateBlobImageFromVideo(video) {
    return new Promise<any>((resolve, reject) => {
      this.checkCanvasToBlobCompatibility();

      // Create a canvas and use it to draw a snapshot of the video feed for creating a BLOB from
      let canvas = document.createElement("canvas");
      let context = canvas.getContext('2d');

      let vidHeight = video.videoHeight;
      let vidWidth = video.videoWidth;

      context.canvas.height = vidHeight;
      context.canvas.width = vidWidth;

      context.drawImage(video, 0, 0, vidWidth, vidHeight, 0, 0, vidWidth, vidHeight);

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        }, "image/jpeg", this.blobQuality);
    });
  }

  /**
   * Generates a blob image from an HTML video element
   *
   * @param {HTML Element} video
   */
  generateSquareBlobImageFromVideo(video) {
    return new Promise<any>((resolve, reject) => {
      this.checkCanvasToBlobCompatibility();

      // Create a canvas and use it to draw a snapshot of the video feed for creating a BLOB from
      let canvas = document.createElement("canvas");
      let context = canvas.getContext('2d');

      let imgSize = 480;

      context.canvas.width = imgSize;
      context.canvas.height = imgSize;
      context.translate(480, 0);
      context.scale(-1, 1);

      let vidHeight = video.videoHeight;
      let vidWidth = video.videoWidth;

      if (vidHeight < imgSize) {
        imgSize = vidHeight;
      }
      if (vidWidth < imgSize) {
        imgSize = vidWidth;
      }

      let imgXOffset = parseInt((vidWidth - imgSize) / 2 + "");
      let imgYOffset = parseInt((vidHeight - imgSize) / 2 + "");

      context.drawImage(video, imgXOffset, imgYOffset, imgSize, imgSize, 0, 0, 480, 480);

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        }, "image/jpeg", this.blobQuality);
    });
  }

  /**
   * Compresses a blob image from a blob URL
   *
   * @param {string} blobUrl
   */
  compressBlobImage(blobUrl) {
    return new Promise<any>((resolve, reject) => {
      let image = new Image();

      image.onload = () => {
        this.checkCanvasToBlobCompatibility();

        let canvas = document.createElement("canvas");
        let context = canvas.getContext('2d');

        let imgWidth = image.width;
        let imgHeight = image.height;

        context.canvas.height = imgHeight;
        context.canvas.width = imgWidth;

        context.drawImage(image, 0, 0, imgWidth, imgHeight, 0, 0, imgWidth, imgHeight);

        canvas.toBlob(
          (blob) => {
            resolve(blob);
          }, "image/jpeg", this.blobQuality);
      };

      image.src = blobUrl;
    });
  }

  /**
   * Uses image EXIF meta data to check that image orientation is correct.
   * Compresses a blob image from a blob URL
   *
   * @param {string} blobUrl
   */
  orientAndCompressSquareBlobImage(blobUrl) {
    return new Promise<any>((resolve, reject) => {
      let image = new Image();

      image.onload = () => {
        this.checkCanvasToBlobCompatibility();

        let canvasSize = 480;
        let canvas = document.createElement("canvas");
        let context = canvas.getContext('2d');

        context.canvas.height = canvasSize;
        context.canvas.width = canvasSize;

        let exifOrientation = null;

        let imgWidth = image.width;
        let imgHeight = image.height;
        let minImgDimension = imgWidth < imgHeight ? imgWidth : imgHeight;

        let blobQuality = this.blobQuality;

        // Check orientation in EXIF metadata
        EXIF.getData(image as unknown as string, () => {
          let allMetaData = EXIF.getAllTags(this);
          exifOrientation = allMetaData.Orientation;

          // transform context before drawing image
          switch (exifOrientation) {
            case 2:
              context.transform(-1, 0, 0, 1, canvasSize, 0);
              break;
            case 3:
              context.transform(-1, 0, 0, -1, canvasSize, canvasSize);
              break;
            case 4:
              context.transform(1, 0, 0, -1, 0, canvasSize);
              break;
            case 5:
              context.transform(0, 1, 1, 0, 0, 0);
              break;
            case 6:
              context.transform(0, 1, -1, 0, canvasSize, 0);
              break;
            case 7:
              context.transform(0, -1, -1, 0, canvasSize, canvasSize);
              break;
            case 8:
              context.transform(0, -1, 1, 0, 0, canvasSize);
              break;
            default:
              context.transform(1, 0, 0, 1, 0, 0);
          }

          let imgXOffset = imgWidth === minImgDimension ? 0 : parseInt((imgWidth - imgHeight) / 2 + "");
          let imgYOffset = imgHeight === minImgDimension ? 0 : parseInt((imgHeight - imgWidth) / 2 + "");

          context.drawImage(image, imgXOffset, imgYOffset, minImgDimension, minImgDimension, 0, 0, canvasSize, canvasSize);

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            }, "image/jpeg", blobQuality);
        });
      };

      image.src = blobUrl;
    });
  }

  /**
   * Uses image EXIF meta data to check that image orientation is correct.
   * Compresses a blob image from a blob URL
   *
   * @param {string} blobUrl
   */
  orientAndCompressBlobImage(blobUrl) {
    return new Promise<any>((resolve, reject) => {
      let image = new Image();

      image.onload = () => {
        this.checkCanvasToBlobCompatibility();

        let canvas = document.createElement("canvas");
        let context = canvas.getContext('2d');

        let exifOrientation = null;

        let imgWidth = image.width;
        let imgHeight = image.height;

        let blobQuality = this.blobQuality;

        // Check orientation in EXIF metadata
        EXIF.getData(image as unknown as string, () => {
          let allMetaData = EXIF.getAllTags(this);
          exifOrientation = allMetaData.Orientation;

          // Set proper canvas dimensions before transform & export
          if ([5, 6, 7, 8].indexOf(exifOrientation) !== -1) {
            context.canvas.height = imgWidth;
            context.canvas.width = imgHeight;
          }
          else {
            context.canvas.height = imgHeight;
            context.canvas.width = imgWidth;
          }

          // transform context before drawing image
          switch (exifOrientation) {
            case 2:
              context.transform(-1, 0, 0, 1, imgWidth, 0);
              break;
            case 3:
              context.transform(-1, 0, 0, -1, imgWidth, imgHeight);
              break;
            case 4:
              context.transform(1, 0, 0, -1, 0, imgHeight);
              break;
            case 5:
              context.transform(0, 1, 1, 0, 0, 0);
              break;
            case 6:
              context.transform(0, 1, -1, 0, imgHeight, 0);
              break;
            case 7:
              context.transform(0, -1, -1, 0, imgHeight, imgWidth);
              break;
            case 8:
              context.transform(0, -1, 1, 0, 0, imgWidth);
              break;
            default:
              context.transform(1, 0, 0, 1, 0, 0);
          }

          context.drawImage(image, 0, 0, imgWidth, imgHeight);

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            }, "image/jpeg", blobQuality);
        });
      };

      image.src = blobUrl;
    });
  }

  // Number Formatting ///////////////////////////////////////////////

  static padNumber(number, size, ignoreClipping) {
    let sign = Math.sign(number) === -1 ? '-' : '';

    if (ignoreClipping && (Math.abs(number) + "").length > size) {
      return sign + Math.abs(number);
    }

    return sign + new Array(size).concat([Math.abs(number)]).join('0').slice(-size);
  }

  static numberToTwoDP(number) {
    return Math.round((number + 0.00001) * 100) / 100;
  }

  // Colour Conversion ///////////////////////////////////////////////

  /**
   * Changes the shade of a HEX colour by a given percentage value
   *
   * @param {String} color
   * @param {Number} percent
   * @returns {String}
   */
  static shadeColor(color, percent) {
    let f = parseInt(color.slice(1), 16), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = f >> 16, G = f >> 8 & 0x00FF, B = f & 0x0000FF;
    return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
  }

  /**
   * Returns rgba version of integer colour
   *
   * @param {int} num
   * @returns {string} rgba color string
   */
  static intToRgbaColor(num) {
    num >>>= 0;
    let b = num & 0xFF,
      g = (num & 0xFF00) >>> 8,
      r = (num & 0xFF0000) >>> 16;

    return "rgba(" + [r, g, b, 1].join(",") + ")";
  }

  /**
   * Returns hex version of integer colour
   *
   * @param {int} num
   * @returns {string} hex color string
   */
  static intToHexColor(num) {
    return UtilService.rgbaToHex(UtilService.intToRgbaColor(num));
  }

  /**
   * Returns integer version of rgba colour
   * Required format: 'rgba(xxx,xxx,xxx,1)' or '#xxxxxx'
   *
   * @param {string} col
   * @returns {int} num color string
   */
  static rgbaOrHexColorToInt(col) {
    let vals;

    if (col[0] === '#') {
      vals = [
        parseInt(col.slice(1, 3), 16),
        parseInt(col.slice(3, 5), 16),
        parseInt(col.slice(5, 7), 16)
      ];
    }
    else if (col[0] === 'r') {
      col = col.slice(col.indexOf('(') + 1, col.indexOf(')') - 2);
      vals = col.split(',');
    }
    else {
      throw new Error('"' + col + '" is not a valid colour format. Must be rgba or hex');
    }

    return parseInt(vals[0]) * 65536 + parseInt(vals[1]) * 256 + parseInt(vals[2]);
  }

  /**
   * Converts a rgba string to a hex string
   *
   * @param {string} rgb eg. 'rgba(xxx,xxx,xxx,1)'
   * @returns {string} hex eg. '#xxxxxx'
   */
  static rgbaToHex(rgb) {
    rgb = rgb.slice(rgb.indexOf('(') + 1, rgb.indexOf(')') - 2);
    let vals = rgb.split(',');

    return "#" + ((1 << 24) + (parseInt(vals[0]) << 16) + (parseInt(vals[1]) << 8) + parseInt(vals[2])).toString(16).slice(1);
  }

  /**
   * Converts a hex string and an alpha value to an rgba string
   *
   * @param {String} hex
   * @param {Number} alpha
   * @returns {String}
   */
  static hexToRgba(hex, alpha) {
    let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    return "rgba(" + r + ", " + g + ", " + b + ", " + (alpha || 1) + ")";
  }

  // Date Conversion /////////////////////////////////////////////////

  /**
   * Generates an array of date objects for a week based on the given start date
   *
   * @param {Date} startDate
   * @returns {Array<Date>}
   */
  static generateWeeksDates(startDate) {
    let week = [];
    let date = _.cloneDeep(startDate);
    date.setHours(0, 0, 0, 0);

    for (let i = 0; i < 7; i++) {
      week.push(date);

      date = _.cloneDeep(date);
      date.setDate(date.getDate() + 1);
    }

    return week;
  }

  /**
   * Removes seconds and milliseconds from a Date object
   *
   * @param {Date} date
   * @returns {Date}
   */
  static roundDateToMins(date) {
    date = _.cloneDeep(date);
    date.setSeconds(0, 0);
    return date;
  }

  /**
   * Formats a date object into a string for posting to API
   *
   * @param {Date} date
   * @param {Boolean} includeTime
   * @returns {String}
   */
  static formatDateForPosting(date, includeTime) {
    if (includeTime) {
      return UtilService.dateToDateTimeString(date, 'YYYYMMDD HH:mm');
    }
    else {
      return UtilService.dateToDateString(date, 'YYYYMMDD');
    }
  }

  /**
   * Converts a date object into an hours based duration in decimal format
   * eg. 01:30 -> 1.5
   *
   * @param {Date} date
   * @returns {number}
   */
  static dateAsHoursMinsDecimal(date) {
    let hours = date.getHours();
    let mins = date.getMinutes();

    return hours + (mins / 60);
  }

  /**
   * Converts an ISO date string to an hours based duration in decimal format
   *
   * @param {string} isoString
   * @returns {number}
   */
  static localTimeISOStringToHoursMinsDecimal(isoString) {
    return UtilService.dateAsHoursMinsDecimal(new Date(isoString));
  }

  /**
   * Returns the absolute difference between two date objects as an hours based duration in decimal format
   * eg. 02:30 - 04:00 -> 1.5
   *
   * @param {Date} dateA
   * @param {Date} dateB
   * @returns {number}
   */
  static differenceBetweenTwoDatesAsHoursDecimal(dateA, dateB) {
    let totalSeconds = Math.abs(Math.ceil(dateA.valueOf() - dateB.valueOf()));

    return moment.duration(totalSeconds, 'milliseconds').asHours();
  }

  /**
   * Converts an hours based duration in decimal format to an ISO date string
   *
   * @param {number} dec
   * @returns {string}
   */
  static hoursDecimalToLocalTimeISOString(dec) {
    let date = this.hoursDecimalAsDate(dec);
    return moment(moment(date), moment.ISO_8601).format();
  }

  /**
   * Converts an hours based duration in decimal format into a Date object
   * eg. 1.5 -> 01:30
   *
   * @param {number} dec
   * @returns {Date}
   */
  static hoursDecimalAsDate(dec) {
    let hours = moment.duration(dec, 'hours').hours();
    let mins = moment.duration(dec, 'hours').minutes();

    let date = new Date();
    date.setHours(hours, mins, 0, 0);

    return date;
  }

  /**
   * Converts an hours based duration in decimal format into an array with two numeric values.
   * The first value is the number of hours, the second being the number of minutes
   * eg. 1.75 -> [1, 45]
   *
   * @param {number} dec
   * @returns {[number , number]}
   */
  static decimalAsHoursMins(dec) {
    let hours = Math.floor(dec);
    let mins = Math.round(60 * (dec - hours));

    // Need to account for possible rounding up of decimal
    if (mins === 60) {
      hours++;
      mins = 0;
    }

    return [hours, mins];
  }

  /**
   * Formats a date object into an ISO date string
   *
   * @param {Date} date
   * @returns {string}
   */
  static dateToLocalTimeISOString(date) {
    return moment(moment(date), moment.ISO_8601).format();
  }

  static dateIsValid(date) {
    return Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime());
  }

  /**
   * Converts date object to date string
   * If no stringFormat is provided, defaults to "YYYY-MM-DD"
   *
   * @param {Date} date
   * @param {String} stringFormat
   * @returns {String}
   */
  static dateToDateString(date, stringFormat) {
    return UtilService.dateToDateTimeString(date, (stringFormat || "YYYY-MM-DD"));
  }

  /**
   * Converts date object to date time string
   * If no stringFormat is provided, defaults to "YYYY-MM-DD HH:mm"
   *
   * @param {Date} date
   * @param {String} stringFormat
   * @returns {String}
   */
  static dateToDateTimeString(date, stringFormat) {
    if (!UtilService.dateIsValid(date)) {
      return null;
    }
    if (stringFormat) {
      return moment(date).format(stringFormat);
    }
    else {
      return moment(date).format("YYYY-MM-DD HH:mm");
    }
  }

  /**
   * Coverts date string to date object with time set to midnight
   * If no stringFormat is provided, defaults to "YYYY-MM-DD"
   *
   * @param {String} dateString
   * @param {String} stringFormat
   * @returns {Date}
   */
  static dateStringToDate(dateString, stringFormat) {
    return UtilService.dateTimeStringToDate(dateString, (stringFormat || "YYYY-MM-DD"));
  }

  /**
   * Converts date string to date object
   * If no stringFormat is provided, defaults to "YYYY-MM-DD HH:mm"
   *
   * @param {String} dateString
   * @param {String} stringFormat
   * @returns {Date}
   */
  static dateTimeStringToDate(dateString, stringFormat) {
    let date;

    if (stringFormat) {
      date = moment(dateString, stringFormat).toDate();
    }
    else {
      date = moment(dateString, "YYYY-MM-DD HH:mm").toDate();
    }

    // Check for invalid dates
    return UtilService.dateIsValid(date) ? date : null;
  }

  /**
   * Converts a time string into a Date object
   * e.g 'HH:MM', '09:30'
   *
   * @param {String} time
   * @returns {Date}
   */
  static hoursMinsStringToDate(time) {
    return UtilService.dateTimeStringToDate(time, "HH:mm");
  }

  /**
   * Check if the given date is the last day in the month
   *
   * @param {Date} date
   * @returns {boolean}
   */
  static isLastDayInMonth(date) {
    let nextDay = _.cloneDeep(date);
    nextDay.setDate(nextDay.getDate() + 1);

    return date.getMonth() !== nextDay.getMonth();
  }

  /**
   * Parses a json object returns the unparsed data if an error is thrown
   *
   * @param data
   * @returns {any}
   */
  static parseJSON(data: any): any {
    try {
      return JSON.parse(data);
    }
    catch (err) {
      return data;
    }
  }

}
