import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';

import { TeamService } from './../team/team.service';
import { HistoryImageService } from './../history-image/history-image.service';
import { EmployeeImageService } from './../employee-image/employee-image.service';
import { ClockService } from './../clock/clock.service';
import { UtilService } from './../util/util.service';

import * as _ from 'lodash';
import * as moment from 'moment';
import PouchDB from 'pouchdb-browser';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  employees: any[];
  employeesMap: any;
  employeeLabels: any[];

  db: any;

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public clockService: ClockService,
    public employeeImageService: EmployeeImageService,
    public historyImageService: HistoryImageService,
    public teamService: TeamService,
    private domSanitizer: DomSanitizer
  ) { }

  initialiseService() {
    return new Promise<void>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve();
      }
      else {
        this.loadEmployees()
          .then(() => {
            this.serviceSetup = true;
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }
    });
  }

  clearServiceData(clearDatabases) {
    this.employees = [];
    this.employeesMap = {};
    this.employeeLabels = [];

    if (clearDatabases) {
      this.clearEmployeesFromDB();
    }
    this.serviceSetup = false;
  }

  getEmployees() {
    return _.cloneDeep(this.employees);
  }

  getSelectedTeamEmployees() {
    let teamEmployeeMap = this.teamService.getSelectedTeamEmployeeMap();

    // No selected team so show all employees
    if (teamEmployeeMap === null) {
      return _.cloneDeep(this.employees);
    }
    else {
      let teamEmployees = [];

      for (let i = 0; i < this.employees.length; i++) {
        let employee = this.employees[i];

        if (teamEmployeeMap[employee.employee_key] !== undefined) {
          teamEmployees.push(employee);
        }
      }

      return _.cloneDeep(teamEmployees);
    }
  }

  getSingleEmployee(employee_key) {
    return this.employeesMap[employee_key] || null;
  }

  initDB() {
    try {
      this.db = new PouchDB("ph-droppah-employees", {
        adapter: "idb"
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  loadEmployeesIntoDB(employees) {
    if (this.db) {
      let dbEmployees = _.cloneDeep(employees);

      for (let i = 0; i < dbEmployees.length; i++) {
        let emp = dbEmployees[i];

        // Set employee database ID as their employee key
        // ID needs to be a string
        emp._id = emp.employee_key + "";
      }

      this.db.bulkDocs(dbEmployees)
        .catch((err) => {
          console.log(err);
        });
    }
  }

  loadEmployeesFromDB() {
    return new Promise<any>((resolve, reject) => {
      if (this.db) {
        this.db.allDocs({ include_docs: true })
          .then((docs) => {
            let employees = [];

            for (let i = 0; i < docs.rows.length; i++) {
              let doc = docs.rows[i].doc;

              // Remove DB related properties from employee
              delete doc._id;
              delete doc._rev;

              employees.push(doc);
              resolve(employees);
            }
          })
          .catch(() => {
            reject();
          });
      }
      else {
        reject();
      }
    });
  }

  clearEmployeesFromDB() {
    return new Promise<void>((resolve, reject) => {
      if (this.db) {

        this.db.destroy()
          .then(() => {
            this.initDB();
            resolve();
          })
          .catch((err) => {
            console.log(err);
            resolve();
          });
      }
      else {
        resolve();
      }
    });
  }

  /**
   * Clocks in an employee and saves their clock image to employeeImageService and historyImageService
   *
   * @param {Object} employee
   * @param {Blob} imgBlob
   */
  clockInEmployee(employee, imgBlob) {
    return new Promise<void>((resolve, reject) => {
      let clockTimeString = UtilService.dateToDateTimeString(new Date(), null);

      this.clockService.postClockInRecord(employee, clockTimeString)
        .then(() => {

          this.historyImageService.setHistoryImage(employee.employee_key, clockTimeString, imgBlob);

          this.employeeImageService.setEmployeeImage(employee.employee_key, clockTimeString, imgBlob)
            .then((imageData) => {
              this.employeesMap[employee.employee_key].imgUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(imageData.imgUrl);
              this.employeesMap[employee.employee_key].imgTime = imageData.imgTime;
              resolve();
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Clocks out an employee and saves their clock image to employeeImageService and historyImageService
   *
   * @param {Object} employee
   * @param {Blob} imgBlob
   */
  clockOutEmployee(employee, imgBlob) {
    return new Promise<void>((resolve, reject) => {
      let clockTimeString = UtilService.dateToDateTimeString(new Date(), null);

      this.clockService.postClockOutRecord(employee, clockTimeString)
        .then(() => {

          this.historyImageService.setHistoryImage(employee.employee_key, clockTimeString, imgBlob);

          this.employeeImageService.setEmployeeImage(employee.employee_key, clockTimeString, imgBlob)
            .then((imageData) => {
              this.employeesMap[employee.employee_key].imgUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(imageData.imgUrl);
              this.employeesMap[employee.employee_key].imgTime = imageData.imgTime;
              resolve();
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Breaks in an employee
   *
   * @param {Object} employee
   */
  breakInEmployee(employee) {
    return new Promise<void>((resolve, reject) => {
      let breakTimeString = UtilService.dateToDateTimeString(new Date(), null);

      this.clockService.postBreakInRecord(employee, breakTimeString)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Breaks out an employee
   *
   * @param {Object} employee
   */
  breakOutEmployee(employee) {
    return new Promise<void>((resolve, reject) => {

      let breakTimeString = UtilService.dateToDateTimeString(new Date(), null);

      this.clockService.postBreakOutRecord(employee, breakTimeString)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Add or delete clock note to/from employee clock record
   *
   * @param {Object} employee
   * @param {string} note
   * @param {number} clockNoteKey
   * @param {boolean} deleteNote
   */
  addEmployeeClockNote(employee, note, clockNoteKey, deleteNote) {
    return new Promise<any>((resolve, reject) => {
      this.clockService.postClockNote(employee, note, clockNoteKey, deleteNote)
        .then((updatedNote) => {
          resolve(updatedNote);
        })
        .catch(() => {
          reject();
        });
    });
  }

  getInactiveEmployees() {
    return new Promise<any>((resolve, reject) => {

      this.clockService.loadClockRecordsAsMap()
        .then((records) => {
          let employees = this.getSelectedTeamEmployees();
          let inactiveEmployees = [];

          // Filter to employees without current clock records
          for (let i = 0; i < employees.length; i++) {
            let emp = employees[i];

            if (!records[emp.employee_key]) {
              inactiveEmployees.push(emp);
            }
          }

          EmployeeService.sortEmployeesByName(inactiveEmployees);
          resolve(inactiveEmployees);
        })
        .catch(() => {
          reject();
        });

    });
  }

  /**
   * Loads all employee clock records then splits employees into two groups,
   * those with an active clock record and those without
   */
  getGroupedClockEmployees() {
    return new Promise<any>((resolve, reject) => {

      this.clockService.loadClockRecordsAsMap()
        .then((records) => {
          let employees = this.getEmployees();

          let clockedEmployees = [];
          let inactiveEmployees = [];

          // Sort employees into clocked/inactive arrays
          for (let i = 0; i < employees.length; i++) {
            let emp = employees[i];
            emp.clockRecord = records[emp.employee_key] || null;
            emp.isOnBreak = false;
            emp.isOffSite = EmployeeService.employeeIsOffSite(emp);

            if (emp.clockRecord) {
              clockedEmployees.push(emp);
            }
            else {
              inactiveEmployees.push(emp);
            }
          }

          // For each clocked in employee, determine if they're currently on break
          for (let i = 0; i < clockedEmployees.length; i++) {
            let emp = clockedEmployees[i];
            let empClockBreaks = emp.clockRecord.clock_breaks;

            for (let j = 0; j < empClockBreaks.length; j++) {
              if (!empClockBreaks[j].end_time) {
                emp.isOnBreak = true;
                break;
              }
            }
          }

          clockedEmployees = EmployeeService.sortEmployees(clockedEmployees);
          EmployeeService.sortEmployeesByName(inactiveEmployees);

          resolve([clockedEmployees, inactiveEmployees]);
        })
        .catch(() => {
          reject();
        });
    });
  }

  static employeeIsOffSite(employee) {
    if (employee.clockRecord) {
      return !employee.imgTime ||
        !moment(employee.clockRecord.clock_in_time).isSame(moment(employee.imgTime), "minutes");
    }
    return false;
  }

  /**
   * Loads all employees and their images from the clockImageDb, then assigns an image to each employee
   */
  loadEmployees() {
    return new Promise<void>((resolve, reject) => {

      this.utilService.APIGet("employee/all", false, false, null, false)
        .then((employees) => {
          this.loadEmployeesIntoDB(employees);

          this.bindEmployeeImages(employees)
            .then(() => { resolve(); })
            .catch(() => { reject(); });
        })
        // Fallback to loading employees from IndexedDB
        .catch(() => {
          this.loadEmployeesFromDB()
            .then((employees) => {

              this.bindEmployeeImages(employees)
                .then(() => { resolve(); })
                .catch(() => { reject(); });
            })
            .catch(() => {
              reject();
            })
        });
    });
  }

  bindEmployeeImages(employees) {
    return new Promise<any>((resolve, reject) => {
      let imagePromises = [];

      // For each unfinished employee, get their image from the employeeImageDb
      for (let i = 0; i < employees.length; i++) {
        let employee = EmployeeService.setupEmployee(employees[i]);

        if (!employee.has_final_pay) {
          imagePromises.push(this.employeeImageService.getEmployeeImageUrlAndTime(employee.employee_key));
        }
      }

      if (imagePromises.length) {
        // Wait for all async calls to the employeeImageDb to complete
        forkJoin(imagePromises)
          .subscribe(
            (employeeImageData) => {
              this.employees = [];
              this.employeesMap = {};
              let imageMap = {};

              // Generate an employee URL map from the result the async calls to get image urls
              for (let i = 0; i < employeeImageData.length; i++) {
                let data = employeeImageData[i];

                imageMap[data["employee_key"]] = data;
              }

              // For each employee, assign it's imgUrl from the employee URL map we just created
              for (let i = 0; i < employees.length; i++) {
                let employee = employees[i];

                if (EmployeeService.employeeIsActive(employee)) {
                  employee.imgUrl = imageMap[employee.employee_key].imgUrl ? this.domSanitizer.bypassSecurityTrustResourceUrl(imageMap[employee.employee_key].imgUrl) : null;
                  employee.imgTime = imageMap[employee.employee_key].imgTime || null;

                  this.employees.push(employee);
                  this.employeesMap[employee.employee_key] = employee;
                }
              }

              this.setupEmployeeLabels();
              resolve(this.employees);
            },
            (err) => {
              reject();
            }
          );
      }
      else {
        this.employees = [];
        this.employeesMap = {};
        resolve(this.employees);
      }
    });
  }

  /**
   * Generates a map of all labels from all employees
   */
  setupEmployeeLabels() {
    let labelMap = {};

    for (let i = 0; i < this.employees.length; i++) {
      let emp = this.employees[i];

      for (let j = 0; j < emp.labels.length; j++) {
        let label = emp.labels[j];

        if (!labelMap[label.title]) {
          labelMap[label.title] = label;
        }
      }
    }

    this.employeeLabels = [];

    for (let title in labelMap) {
      if (labelMap.hasOwnProperty(title)) {

        this.employeeLabels.push(labelMap[title]);
      }
    }
  }

  static sortEmployees(employees) {
    let breakEmployees = [];
    let offSiteEmployees = [];
    let otherEmployees = [];

    for (let i = 0; i < employees.length; i++) {
      let emp = employees[i];

      if (emp.isOffSite) {
        offSiteEmployees.push(emp);
      }
      else if (emp.isOnBreak) {
        breakEmployees.push(emp);
      }
      else {
        otherEmployees.push(emp);
      }
    }

    EmployeeService.sortEmployeesByBreakInTime(breakEmployees);
    EmployeeService.sortEmployeesByName(offSiteEmployees);
    EmployeeService.sortEmployeesByName(otherEmployees);

    return breakEmployees.concat(otherEmployees).concat(offSiteEmployees);
  }

  static sortEmployeesByName(employees) {
    employees.sort((a, b) => {
      let empCodeA = a.employee_code.toUpperCase();
      let empCodeB = b.employee_code.toUpperCase();

      if (empCodeA > empCodeB) {
        return 1;
      }
      else if (empCodeA < empCodeB) {
        return -1;
      }
      return 0;
    });
  }

  static sortEmployeesByBreakInTime(employees) {
    employees.sort((a, b) => {
      let breakA = EmployeeService.getActiveEmployeeBreak(a);
      let breakB = EmployeeService.getActiveEmployeeBreak(b);

      let breakStartA = breakA ? breakA.start_time : new Date();
      let breakStartB = breakB ? breakB.start_time : new Date();

      if (breakStartA > breakStartB) {
        return 1;
      }
      else if (breakStartA < breakStartB) {
        return -1;
      }
      return 0;
    });
  }

  static getActiveEmployeeBreak(employee) {
    if (employee.clockRecord && employee.clockRecord.clock_breaks) {
      let breaks = employee.clockRecord.clock_breaks;

      for (let i = 0; i < breaks.length; i++) {
        let br = breaks[i];

        if (!br.end_time) {
          return br;
        }
      }
    }
    return null;
  }

  static employeeIsActive(employee) {
    return employee.active_flag &&
      !employee.has_final_pay &&
      moment(employee.employment_start_date).isSameOrBefore(moment()) &&
      (!employee.employment_finish_date || moment(employee.employment_finish_date).isSameOrAfter(moment()));
  }

  /**
   * Format non primitive employee properties
   *
   * @param {Object} employee
   * @returns {Object}
   */
  static setupEmployee(employee) {
    employee.employment_start_date = UtilService.dateStringToDate(employee.employment_start_date, null);
    employee.employment_finish_date = UtilService.dateStringToDate(employee.employment_finish_date, null);
    employee.birth_date = UtilService.dateStringToDate(employee.birth_date, null);
    employee.kiwisaver_enrolment_date = UtilService.dateStringToDate(employee.kiwisaver_enrolment_date, null);
    employee.kiwisaver_opt_out_date = UtilService.dateStringToDate(employee.kiwisaver_opt_out_date, null);

    if (employee.additional_data) {
      employee.additional_data = JSON.parse(employee.additional_data);
    }
    if (!employee.labels) {
      employee.labels = [];
    }

    // Create a copy of labels in a map for faster filtering
    employee.labelsMap = {};
    for (let i = 0; i < employee.labels.length; i++) {
      let label = employee.labels[i];

      employee.labelsMap[label.title] = label;
    }

    return employee;
  }

}
