
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UIRouterModule, UIRouter } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';

import { StateChangeService } from './services/state-change/state-change.service';

import { AppComponent } from './app.component';

import { LoginModule } from './pages/login/login.module';
import { CoreModule } from './pages/core/core.module';
import { ClockModule } from './pages/clock/clock.module';
import { HistoryModule } from './pages/history/history.module';

import { PipesModule } from './pipes/pipes.module';

export function UIRouterConfig(router: UIRouter) {
  router.urlService.rules.otherwise({ state: 'app.clock.view' });
}

@NgModule({
  imports: [
    IonicModule.forRoot(),
    UIRouterModule.forRoot({
      config: UIRouterConfig,
      useHash: true
    }),
    HttpClientModule,
    FormsModule,
    BrowserModule,
    PipesModule,
    CoreModule,
    LoginModule,
    ClockModule,
    HistoryModule,
    ServiceWorkerModule.register('ngsw-worker.js')
  ],
  providers: [
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: true }),
    }
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(private stateChangeService: StateChangeService) {
    this.stateChangeService.initTransitionListeners();
  }
}
