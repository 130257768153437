import { Injectable } from '@angular/core';

import { ProjectService } from './../project/project.service';
import { TeamService } from './../team/team.service';
import { CompanyService } from './../company/company.service';
import { ClockQueueService } from './../clock-queue/clock-queue.service';
import { UtilService } from './../util/util.service';

import * as _ from 'lodash';
import PouchDB from 'pouchdb-browser';

@Injectable({
  providedIn: 'root'
})
export class ClockService {

  blobQuality: number = 0.4;
  db: any;

  constructor(
    public utilService: UtilService,
    public clockQueueService: ClockQueueService,
    public companyService: CompanyService,
    public teamService: TeamService,
    public projectService: ProjectService
  ) { }


  clearServiceData(clearDatabases) {
    if (clearDatabases) {
      this.clearClocksFromDB();
    }
  }

  initDB() {
    try {
      this.db = new PouchDB("ph-droppah-clockRecords", {
        adapter: "idb"
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  loadClocksIntoDB(clocks) {

    if (this.db) {
      let dbClocks = _.cloneDeep(clocks);

      for (let i = 0; i < dbClocks.length; i++) {
        let clock = dbClocks[i];

        // Set clock database ID as their employee key
        // ID needs to be a string
        clock._id = clock.employee_key + "";
      }

      // Clear the db before loading updated list of clock records into db
      this.clearClocksFromDB()
        .then(() => {

          this.db.bulkDocs(dbClocks)
            .then(() => {

            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  }

  loadClocksFromDB() {

    return new Promise<any>((resolve, reject) => {
      if (this.db) {

        this.db.allDocs({ include_docs: true })
          .then((docs) => {
            let clocks = [];

            for (let i = 0; i < docs.rows.length; i++) {
              let doc = docs.rows[i].doc;

              // Remove DB related properties from clock
              delete doc._id;
              delete doc._rev;

              clocks.push(doc);
            }

            resolve(clocks);
          })
          .catch(() => {
            resolve([]);
          });
      }
      else {
        resolve([]);
      }
    });
  }

  clearClocksFromDB() {
    return new Promise<void>((resolve, reject) => {
      if (this.db) {

        this.db.destroy()
          .then(() => {
            this.initDB();
            resolve();
          })
          .catch((err) => {
            console.log(err);
            resolve();
          });
      }
      else {
        resolve();
      }
    });
  }

  getDBClock(employee_key) {

    return new Promise<any>((resolve, reject) => {
      // Row ID must be a string
      let _id = employee_key + "";

      this.db.get(_id)
        .then((doc) => {

          resolve(doc);
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    });
  }

  setDBClockRecord(data) {

    return new Promise<void>((resolve, reject) => {
      // Row ID must be a string
      let _id = data.employee_key + "";

      this.db.get(_id)
        // Overwriting an existing doc
        .then((doc) => {
          doPut(data.employee_key, data, doc._rev);
        })
        // Creating new doc
        .catch(() => {
          doPut(data.employee_key, data, null);
        });


      let doPut = (employee_key, data, rev) => {
        data = _.cloneDeep(data);

        // Row ID must be a string
        data._id = employee_key + "";
        data._rev = rev;

        this.db.put(data)
          .then(() => {

            resolve();
          })
          .catch(() => {
            reject();
          })
      };
    });
  }

  clearDBClockRecord(data) {
    return new Promise<void>((resolve, reject) => {
      // Row ID must be a string
      let _id = data.employee_key + "";

      this.db.get(_id)
        .then((doc) => {

          this.db.remove(doc)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            })
        });
    });
  }

  /**
   * Loads all active clock records and then returns them as a map
   */
  loadClockRecordsAsMap() {

    return new Promise<any>((resolve, reject) => {

      this.clockQueueService.flushQueue()
        .then(() => {
          getClocks();
        })
        .catch(() => {
          getClocks();
        });

      let getClocks = () => {
        this.utilService.APIGet("clock", false, false, null, false)
          .then((records) => {

            this.loadClocksIntoDB(records);
            generateClockMap(records);
          })
          .catch(() => {

            this.loadClocksFromDB()
              .then((records) => {
                generateClockMap(records);
              })
          });
      };

      let generateClockMap = (records) => {
        let recordsMap = {};

        for (let i = 0; i < records.length; i++) {
          let record = records[i];
          recordsMap[record.employee_key] = this.setupClockRecord(record);
        }
        resolve(recordsMap);
      };

    });
  }

  /**
   * Called by all clock/break in/out provider functions to ensure clock/break entries
   * are handled appropriately when the device is offline or has just come back online
   *
   * @param {string} url
   * @param {Object} clockData
   */
  postClockData(url, clockData) {

    return new Promise<any>((resolve, reject) => {

      // Check we're online
      this.utilService.tryAPI()
        .then(() => {

          // Make sure we first post any existing clock data that hasn't yet been posted
          this.clockQueueService.flushQueue()
            .then(() => {

              this.updateClockRecordInLocalDB(clockData);
              this.utilService.APIPost(url, clockData)
                .then((result) => {
                  resolve(result);
                })
                .catch(() => {
                  reject();
                });
            })
            // If the batch post of all queued clock data failed, then assume we've lost internet access and
            // add our new clock data to the queue for posting later
            .catch(() => {
              this.clockQueueService.addToQueue(clockData);
              this.updateClockRecordInLocalDB(clockData)
                .then((dbClockData) => {
                  resolve(dbClockData);
                })
                .catch(() => {
                  reject();
                });
            });
        })
        // If we're offline then add the clockData to the queue for posting later
        .catch(() => {
          this.clockQueueService.addToQueue(clockData);
          this.updateClockRecordInLocalDB(clockData)
            .then((dbClockData) => {
              resolve(dbClockData);
            })
            .catch(() => {
              reject();
            });
        });
    });
  }

  // TODO split this into 3 separate functions?
  // ie 1 for clockRecords, 1 for breaks, 1 for notes
  updateClockRecordInLocalDB(data) {
    return new Promise<any>((resolve, reject) => {

      this.getDBClock(data.employee_key)
        .then((clockRecord) => {
          // Clock In
          if (data.clock_in_time) {
            clockRecord = {
              clock_breaks: [],
              clock_in_time: data.clock_in_time,
              clock_key: null,
              clock_notes: [],
              clock_out_time: null,
              company_code: this.companyService.getCompany().company_code,
              employee_key: data.employee_key
            };

            this.setDBClockRecord(clockRecord);
            resolve(clockRecord);
          }
          // Clock Out
          else if (data.clock_out_time) {
            this.clearDBClockRecord(clockRecord);
            resolve(null);
          }
          // Break In
          else if (data.start_time) {
            let clockBreak = {
              break_duration: 0,
              clock_break_key: null,
              clock_key: clockRecord.clock_key || null,
              end_time: null,
              paid_break_flag: false,
              start_time: data.start_time
            };

            clockRecord.clock_breaks.push(clockBreak);

            this.setDBClockRecord(clockRecord);
            resolve(clockBreak);
          }
          // Break Out
          else if (data.end_time) {
            for (const clockBreak of clockRecord.clock_breaks) {

              if (!clockBreak.end_time) {
                clockBreak.end_time = data.end_time;
                break;
              }
            }

            this.setDBClockRecord(clockRecord);
            resolve(null);
          }
          // Note
          else if (data.note) {
            // New Note
            if (!data.clock_note_key && !data.deleted_flag) {
              let clockNote = {
                clock_note_key: null,
                clock_key: clockRecord.clock_key || null,
                note: data.note
              };
              // New Note
              clockRecord.clock_notes.push(clockNote);

              this.setDBClockRecord(clockRecord);
              resolve(clockNote);
            }
            // Update Note
            else if (!!data.clock_note_key && !data.deleted_flag) {
              for (const clockNote of clockRecord.clock_notes) {

                if (clockNote.clock_note_key === data.clock_note_key) {
                  clockNote.note = data.note;

                  this.setDBClockRecord(clockRecord);
                  resolve(clockNote);
                  break;
                }
              }
            }
            // Delete Note
            else {
              for (let i = 0; i < clockRecord.clock_notes.length; i++) {
                let clockNote = clockRecord.clock_notes[i];

                if (clockNote.clock_note_key === data.clock_note_key) {
                  clockRecord.clock_notes.splice(i, 1);

                  this.setDBClockRecord(clockRecord);
                  resolve(null);
                  break;
                }
              }
            }
          }
        })
        .catch((err) => {
          reject(err);
        })
    });
  }

  /**
   * Clocks in an employee
   *
   * @param {number} employee
   * @param {string} clock_in_time
   */
  postClockInRecord(employee, clock_in_time) {
    let selectedTeam = this.teamService.getSelectedTeam();

    return new Promise<void>((resolve, reject) => {
      let data = {
        employee_key: employee.employee_key,
        clock_in_time: clock_in_time,
        team_key: selectedTeam ? selectedTeam.team_key : null
      };

      this.postClockData("clock", data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Clocks out an employee
   *
   * @param {number} employee
   * @param {string} clock_out_time
   */
  postClockOutRecord(employee, clock_out_time) {

    return new Promise<void>((resolve, reject) => {
      let data = {
        clock_key: employee.clockRecord.clock_key,
        employee_key: employee.employee_key,
        clock_out_time: clock_out_time
      };

      this.postClockData("clock", data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Clocks in an employee
   *
   * @param {number} employee
   * @param {string} start_time
   */
  postBreakInRecord(employee, start_time) {

    return new Promise<void>((resolve, reject) => {
      let data = {
        clock_key: employee.clockRecord.clock_key,
        employee_key: employee.employee_key,
        start_time: start_time
      };

      this.postClockData("clock/break", data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Clocks out an employee
   *
   * @param {number} employee
   * @param {string} end_time
   */
  postBreakOutRecord(employee, end_time) {

    let breakRecord = null;
    let clockBreaks = employee.clockRecord.clock_breaks;

    for (let i = 0; i < clockBreaks.length; i++) {
      if (!clockBreaks[i].end_time) {
        breakRecord = clockBreaks[i];
        break;
      }
    }

    return new Promise<void>((resolve, reject) => {
      let data = {
        clock_key: employee.clockRecord.clock_key,
        employee_key: employee.employee_key,
        clock_break_key: breakRecord.clock_break_key,
        end_time: end_time
      };

      this.postClockData("clock/break", data)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Saves a note for a clock entry
   *
   * @param {Object} employee
   * @param {string} note
   * @param {number} clock_note_key
   * @param {boolean} deleted_flag
   */
  postClockNote(employee, note, clock_note_key, deleted_flag) {

    return new Promise<any>((resolve, reject) => {
      const data = {
        clock_key: employee.clockRecord.clock_key,
        employee_key: employee.employee_key,
        note: note,
        deleted_flag: deleted_flag,
        clock_note_key: clock_note_key,
        updated_time: new Date() // only used for clock events
      };

      this.postClockData("clock/note", data)
        .then((updatedNote) => {
          resolve(updatedNote);
        })
        .catch(() => {
          reject();
        });
    });
  }

  /**
   * Format non primitive record properties
   *
   * @param {Object} record
   * @returns {Object}
   */
  setupClockRecord(record) {
    record.clock_in_time = UtilService.dateTimeStringToDate(record.clock_in_time, null);
    record.clock_out_time = UtilService.dateTimeStringToDate(record.clock_out_time, null);
    record.project = this.projectService.getSingleProject(record.project_key);

    for (let i = 0; i < record.clock_breaks.length; i++) {
      let recordBreak = record.clock_breaks[i];

      recordBreak.start_time = UtilService.dateTimeStringToDate(recordBreak.start_time, null);
      recordBreak.end_time = UtilService.dateTimeStringToDate(recordBreak.end_time, null);

      if (!recordBreak.break_duration && recordBreak.start_time && recordBreak.end_time) {
        // Convert milliseconds to hours
        recordBreak.break_duration = (recordBreak.end_time - recordBreak.start_time) / 1000 / 60 / 60;
      }
    }

    return record;
  }


}
