import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { UtilService } from './../../../services/util/util.service';
import { ClockService } from './../../../services/clock/clock.service';
import { EmployeeService } from './../../../services/employee/employee.service';

@Component({
  selector: 'app-clock-camera',
  templateUrl: './clock-camera.component.html',
  styleUrls: ['./clock-camera.component.scss']
})
export class ClockCameraComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('clockCameraInput') clockCameraInput: ElementRef;

  employee: any;

  video: any;
  image: any;

  pageHeight: number;
  pageWidth: number;

  photoTaken: boolean = false;
  takingPhoto: boolean = false;
  uploadingPhoto: boolean = false;
  pageInitialised: boolean = false;
  cameraSupported: boolean = false;

  pageIsPortrait: boolean = true;
  borderWidth: number;

  clockingEmployee: boolean = false;

  constraints: any = {
    video: {
      facingMode: "user",
      width: 640
    },
    audio: false
  };

  imageBlob: any = null;

  videoDimensions: any = {
    height: null,
    width: null,
    xOffset: null,
    yOffset: null
  };

  fileInput: any;

  isOffline: any;

  constructor(
    public employeeService: EmployeeService,
    public clockService: ClockService,
    public utilService: UtilService,
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit(): void {
    this.subscribeToConnectionEvents();
  }

  ngAfterViewInit() {
    this.fileInput = document.getElementById('clockCameraInput');
    this.video = document.getElementById('clockCameraVideo');
    this.image = document.getElementById('clockCameraImage');

    setTimeout(() => {
      this.initPage();
    })
  }

  ngOnDestroy() {
    if (!!this.video) {
      this.video?.srcObject?.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  fallbackToImageUpload() {
    this.pageInitialised = true;
    this.cameraSupported = false;
    this.utilService.setCameraSupport(this.cameraSupported);

    this.setupCameraContentDimensions();

    setTimeout(() => {
      this.clockCameraInput.nativeElement.click();
    });

    // Watch for orientation change to recalculate camera content dimensions
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.setupCameraContentDimensions();
      }, 500);
    });
  }

  initPage() {
    this.employee = this.uiRouterGlobals.params.employee;

    // utilService maintains whether or not we've previously tried checking for camera support
    let cameraSupported = this.utilService.cameraIsSupported();

    if (!!this.employee) {

      // Camera is supported or camera support hasn't yet been checked and navigator.mediaDevices is accessible
      if ((cameraSupported || cameraSupported === null) &&
        !!navigator && !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia) {

        navigator.mediaDevices.getUserMedia(this.constraints)
          .then((stream) => {
            // Set camera supported value in utilService for easy future reference
            this.cameraSupported = true;
            this.utilService.setCameraSupport(this.cameraSupported);

            this.video.srcObject = stream;

            // Watch for stream to be loaded into the video element so we can calculate
            // video element dimensions from video source dimensions/aspect ratio
            this.video.onloadedmetadata = () => {
              this.setupVideoDimensions(this.video.offsetWidth, this.video.offsetHeight);
              this.pageInitialised = true;
            };

            // Watch for orientation changes so we can recalculate video element dimensions
            window.addEventListener('resize', () => {
              setTimeout(() => {
                this.setupVideoDimensions(this.video.offsetWidth, this.video.offsetHeight);
              }, 500);
            });

          })
          .catch(() => {
            this.fallbackToImageUpload();
          });
      }
      else {
        this.fallbackToImageUpload();
      }
    }
    else {
      this.goBack();
    }
  }

  setupVideoDimensions(vidWidth, vidHeight) {
    // Need to have this wrapped in a try/catch to ensure that if the user goes back before
    // the camera has finished initialising, an error isn't thrown
    try {
      this.videoDimensions = {
        height: null,
        width: null,
        xOffset: null,
        yOffset: null
      };

      this.setupCameraContentDimensions();

      let imageRatio = vidWidth / vidHeight;

      if (this.pageIsPortrait) {
        this.borderWidth = (this.pageHeight - this.pageWidth) / 2;
        // Camera is landscape
        if (imageRatio > 1) {
          this.videoDimensions.width = this.pageWidth * imageRatio;
        }
        // Camera is portrait
        else {
          this.videoDimensions.width = this.pageWidth;
        }
      }
      else {
        this.borderWidth = (this.pageWidth - this.pageHeight) / 2;
        // Camera is landscape
        if (imageRatio > 1) {
          this.videoDimensions.height = this.pageHeight;
        }
        // Camera is portrait
        else {
          this.videoDimensions.height = this.pageWidth * imageRatio;
        }
      }
    }
    catch (err) { }
  }

  setupCameraContentDimensions() {
    let clockInContent = document.getElementById('clockCameraContent');
    this.pageWidth = clockInContent.offsetWidth;
    this.pageHeight = clockInContent.offsetHeight;
    this.pageIsPortrait = this.pageHeight > this.pageWidth;
  }

  imageUploaded(event) {
    this.uploadingPhoto = true;

    if (this.fileInput.files.length) {
      let blobUrl = URL.createObjectURL(this.fileInput.files[0]);

      this.utilService.orientAndCompressSquareBlobImage(blobUrl)
        .then((blob) => {
          this.imageBlob = blob;
          this.image.src = URL.createObjectURL(blob);

          this.photoTaken = true;
          this.takingPhoto = false;
          this.uploadingPhoto = false;

          this.clockEmployee(event);
        });
    }
    else {
      this.uploadingPhoto = false;
    }
  }

  takePhoto() {
    this.takingPhoto = true;

    this.utilService.generateSquareBlobImageFromVideo(this.video)
      .then((blob) => {
        this.imageBlob = blob;
        this.image.src = URL.createObjectURL(blob);

        this.photoTaken = true;
        this.takingPhoto = false;
      });
  }

  newPhoto(event) {
    event.stopPropagation();

    this.photoTaken = false;
    this.imageBlob = null;
    // Empty src
    this.image.src = "";
    this.fileInput.value = null;
  }

  clockEmployee(event) {
    event.stopPropagation();

    if (this.imageBlob) {

      if (!this.clockingEmployee) {
        this.clockingEmployee = true;

        if (this.employee.clockRecord) {

          this.employeeService.clockOutEmployee(this.employee, this.imageBlob)
            .then(() => {
              this.clockingEmployee = false;
              this.stateService.go('app.clock.view');
            })
            .catch(() => {
              this.clockingEmployee = false;
              this.stateService.go('app.clock.view');
            });
        }
        else {

          this.employeeService.clockInEmployee(this.employee, this.imageBlob)
            .then(() => {
              this.clockingEmployee = false;
              this.stateService.go('app.clock.view');
            })
            .catch(() => {
              this.clockingEmployee = false;
              this.stateService.go('app.clock.view');
            });
        }
      }


    }
  }

  goBack() {
    this.stateService.go('app.clock.view');
  }

  subscribeToConnectionEvents() {
    this.isOffline = this.utilService.isOffline;

    this.utilService.getPhOnlineEvent().subscribe((isOnline) => {
      this.isOffline = !isOnline;
    });
  }

}
