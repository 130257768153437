import { StateService } from '@uirouter/core';
import { Component, OnInit } from '@angular/core';

import { AuthService } from './../../../services/auth/auth.service';
import { StateAccessService } from './../../../services/state-access/state-access.service';
import { UtilService } from './../../../services/util/util.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  refreshStateName: string = null;
  refreshStateParams: any = null;

  constructor(
    public stateService: StateService,
    public stateAccessService: StateAccessService,
    public authService: AuthService,
    public utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.refreshStateName = this.stateService.params.refreshStateName || null;
    this.refreshStateParams = this.stateService.params.refreshStateParams || null;

    this.initialiseAppServices();
  }

  initialiseAppServices() {

    this.stateAccessService.ensureServicesInitialised()
      .then(() => {
        this.utilService.loadNewReturnLoginToken().catch(() => {});
        this.stateAccessService.reloadStateAccess(this.refreshStateName, this.refreshStateParams);
      })
      .catch((err) => {
        this.authService.goApplet('login');
      });
  }

}
