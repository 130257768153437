<img class="app-splash-logo"
     src="assets/product-imgs/payhero/shift/PayHero_Shift_Registered.svg">

<div class="ph-spinner app-splash-spinner">
  <ion-spinner></ion-spinner>
</div>

<footer class="app-footer">
  <div class="app-footer-fromFlexitime">
    <img src="../../../../assets/product-imgs/flexitime/FlexiTime_WFM_Full.svg" />
  </div>
</footer>
