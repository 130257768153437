import { Injectable } from '@angular/core';

import { HistoryImageService } from './../history-image/history-image.service';
import { ProjectService } from './../project/project.service';
import { UtilService } from './../util/util.service';

import PouchDB from 'pouchdb-browser';

@Injectable({
  providedIn: 'root'
})
export class ClockHistoryService {

  db: any;

  constructor(
    public utilService: UtilService,
    public projectService: ProjectService,
    public historyImageService: HistoryImageService
  ) { }

  loadClockHistory(from_date, to_date) {
    return new Promise<any>((resolve, reject) => {
      let params = {
        from_date: UtilService.formatDateForPosting(from_date, false),
        to_date: UtilService.formatDateForPosting(to_date, false)
      };

      this.utilService.APIGet("clock/history", params, false, null, false)
        .then((data) => {
          let history = this.setupClockHistory(data);
          ClockHistoryService.sortHistory(history);

          this.historyImageService.setupHistoryImages(history)
            .then((historyWithImages) => {
              resolve(historyWithImages);
            })
            .catch(() => {
              resolve(history);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setupClockHistory(history) {
    for (const clock of history) {
      clock.clock_in_time = UtilService.dateTimeStringToDate(clock.clock_in_time, null);
      clock.clock_out_time = UtilService.dateTimeStringToDate(clock.clock_out_time, null);
      clock.start_time = UtilService.dateTimeStringToDate(clock.start_time, null);
      clock.end_time = UtilService.dateTimeStringToDate(clock.end_time, null);
      clock.segment_date = UtilService.dateStringToDate(clock.segment_date, null);

      clock.project = this.projectService.getSingleProject(clock.project_key);
      clock.project_colour = clock.project_colour ? UtilService.intToHexColor(clock.project_colour) : null;
    }

    return history;
  }

  static sortHistory(history) {
    history.sort((a, b) => {
      if (a.clock_in_time.valueOf() > b.clock_in_time.valueOf()) {
        return 1;
      }
      else if (a.clock_in_time.valueOf() < b.clock_in_time.valueOf()) {
        return -1;
      }
      return 0;
    });
  }

}
