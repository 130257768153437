import { Pipe, PipeTransform } from '@angular/core';

import { UtilService } from './../../services/util/util.service';

import * as moment from 'moment';

@Pipe({
  name: 'hoursToTimePipe'
})
export class HoursToTimePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let isNegative = value < 0;
    let val = Math.abs(value);
    val += 0.0001;

    let hours = Math.floor(moment.duration(val, 'hours').asHours());
    let mins = moment.duration(val, 'hours').minutes();

    return (isNegative ? '-' : '') + UtilService.padNumber(hours, 2, true) + ':' + UtilService.padNumber(mins, 2, false);
  }

}
