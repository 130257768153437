<div class="app-header-bar">
  <div class="app-header-bar-title">
    <span>Team Select</span>
  </div>
  <div class="app-header-bar-right">
    <div class="ph-offlineHeaderTag"
         *ngIf="isOffline">
      OFFLINE
    </div>
    <div class="ph-menuBtn"
         (click)="toggleMenu()">
      <img class="ph-companyLogo"
           alt=""
           [src]="currentCompany.company_logo">
      <i class="ion ion-md-arrow-dropdown ph-menuIcon">
      </i>
    </div>
  </div>
</div>

<div class="ph-menu-container"
     [ngClass]="{ 'ph-menu-containerClosed': !menuOpen }">

  <button class="ph-menu-greyButton">
    <span>{{selectedTeam ? selectedTeam.team_name : 'All Employees'}}</span>
    <i class="ion ion-md-swap"
       *ngIf="multipleCompanyTeams">
    </i>
  </button>

  <button (click)="goToClockView()">
    <span>Clock Entries</span>
  </button>

  <button (click)="goToTimeHistory()">
    <span>Time History</span>
  </button>

  <div class="ph-menu-break"></div>

  <button class="ph-logoutBtn"
          (click)="logout()">
    <span>Sign Out</span>
  </button>

  <div class="ph-menu-appVersionRow">
    v{{appVersion}}
  </div>

</div>

<ion-content>

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshTeams($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="ph-teamSelect-container">

    <div class="ph-teamSelect-teamCard -allTeams"
         *ngIf="multipleCompanyTeams"
         (click)="setSelectedTeam(null)"
         [ngClass]="{ '-selected': selectedTeam === null }">

      <i class="ion ion-ios-people">
      </i>
      <div class="ph-teamSelect-teamName">
        All Employees
      </div>
      <div class="ph-teamSelect-teamEmployees">
        {{allEmployees.length}} Employees
      </div>
      <i class="ion ion-ios-arrow-forward">
      </i>

    </div>

    <div class="ph-teamSelect-teamCard"
         *ngFor="let team of managerTeams"
         (click)="setSelectedTeam(team)"
         [ngClass]="{ '-selected': selectedTeam && selectedTeam.team_key === team.team_key }">

      <i class="ion ion-ios-people">
      </i>
      <div class="ph-teamSelect-teamWorkColour"
           [ngStyle]="{ 'background-color': team.default_project_colour }">
      </div>
      <div class="ph-teamSelect-teamName">
        {{team.team_name}}
      </div>
      <div class="ph-teamSelect-teamWork">
        <span>{{team.default_project_title}}</span>
      </div>
      <div class="ph-teamSelect-teamEmployees">
        {{team.employees.length}} Employees
      </div>
      <i class="ion ion-ios-arrow-forward">
      </i>

    </div>

  </div>

</ion-content>
