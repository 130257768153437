import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { EmployeeService } from './../../../services/employee/employee.service';
import { UtilService } from './../../../services/util/util.service';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-clock-out',
  templateUrl: './clock-out.component.html',
  styleUrls: ['./clock-out.component.scss']
})
export class ClockOutComponent implements OnInit, OnDestroy {

  @ViewChild('noteTextArea') noteTextArea;

  clockEmployee: any;
  breakTotal: number;

  activeBreak: any;

  clockTimer: any;
  clockFlasher: boolean = false;
  breakFlasher: boolean = false;

  clockSeconds: number;
  activeBreakSeconds: number;
  finishedBreakSeconds: number;
  // 23:59:59
  maxTotalSeconds: number = 86399;

  addingNote: boolean = false;
  note: string = '';
  backupNote: string = '';

  loading: boolean = false;

  isOffline: boolean;

  constructor(
    public employeeService: EmployeeService,
    public utilService: UtilService,
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit(): void {
    this.subscribeToConnectionEvents();
    this.initPage();
  }

  ngOnDestroy() {
    if (this.clockTimer) {
      clearInterval(this.clockTimer);
    }
  }

  goBack() {
    this.stateService.go('app.clock.view');
  }

  initPage() {
    let clockEmployee = this.uiRouterGlobals.params.clockEmployee;

    if (clockEmployee) {
      this.clockEmployee = clockEmployee;
      this.breakTotal = 0;

      for (let i = 0; i < this.clockEmployee.clockRecord.clock_breaks.length; i++) {
        let clockBreak = this.clockEmployee.clockRecord.clock_breaks[i];

        this.breakTotal += clockBreak.break_duration;

        if (!clockBreak.end_time) {
          this.activeBreak = clockBreak;
        }
      }

      this.finishedBreakSeconds = Math.ceil(this.breakTotal * 60 * 60);

      this.clockEmployee.clockRecord.clock_breaks.sort((a, b) => {
        if (a.start_time.valueOf() > b.start_time.valueOf()) {
          return -1;
        }
        else if (a.start_time.valueOf() < b.start_time.valueOf()) {
          return 1;
        }
        return 0;
      });

      this.startTimer();
    }
    else {
      this.goBack();
    }
  }

  startTimer() {
    if (!this.clockTimer) {

      this.updateTimerSeconds();

      this.clockTimer = setInterval(() => {
        this.updateTimerSeconds();
      }, 1000)
    }
  }

  updateTimerSeconds() {
    let now = moment();
    let clockStart = moment(this.clockEmployee.clockRecord.clock_in_time);
    let activeBreakStart = this.activeBreak ? moment(this.activeBreak.start_time) : _.cloneDeep(clockStart);

    this.activeBreakSeconds = this.activeBreak ? now.diff(activeBreakStart, "seconds") : 0;
    this.clockSeconds = (this.activeBreak ? activeBreakStart : now).diff(clockStart, "seconds") - this.finishedBreakSeconds;

    if (this.activeBreak) {
      this.clockFlasher = false;
      this.breakFlasher = now.seconds() % 2 === 0;
    }
    else {
      this.clockFlasher = now.seconds() % 2 === 0;
      this.breakFlasher = false;
    }
  }

  clockOut() {
    this.stateService.go('app.clock.camera', { employee: this.clockEmployee });
  }

  breakIn() {
    if (!this.loading) {
      this.loading = true;

      this.employeeService.breakInEmployee(this.clockEmployee)
        .then(() => {
          this.loading = false;
          this.stateService.go('app.clock.view');
        })
        .catch(() => {
          this.loading = false;
          this.stateService.go('app.clock.view');
        });
    }
  }

  breakOut() {
    if (this.activeBreak) {
      this.activeBreak = null;

      if (!this.loading) {
        this.loading = true;

        this.employeeService.breakOutEmployee(this.clockEmployee)
          .then(() => {
            this.loading = false;
            this.stateService.go('app.clock.view');
          })
          .catch(() => {
            this.loading = false;
            this.stateService.go('app.clock.view');
          });
      }
    }
  }

  addNote() {
    this.addingNote = true;

    setTimeout(() => {
      this.noteTextArea.nativeElement.focus();
    });
  }

  cancelNote() {
    this.note = '';
    this.backupNote = '';
    this.addingNote = false;
  }

  saveNote() {
    if (this.note !== this.backupNote) {
      this.backupNote = this.note;

      if (!this.loading) {
        this.loading = true;
      }
      this.employeeService.addEmployeeClockNote(this.clockEmployee, this.note, null, false)
        .then((note) => {
          this.clockEmployee.clockRecord.clock_notes.push(note);
          this.cancelNote();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }

  deleteNote(clockNote) {
    if (!this.loading) {
      this.loading = true;
    }

    this.employeeService.addEmployeeClockNote(this.clockEmployee, clockNote.note, clockNote.clock_note_key, true)
      .then(() => {
        let clockNotes = this.clockEmployee.clockRecord.clock_notes;

        for (let i = 0; i < clockNotes.length; i++) {
          if (clockNotes[i].clock_note_key === clockNote.clock_note_key) {
            clockNotes.splice(i, 1);
          }
        }
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  subscribeToConnectionEvents() {
    this.isOffline = this.utilService.isOffline;

    this.utilService.getPhOnlineEvent().subscribe((isOnline) => {
      this.isOffline = !isOnline;
    });
  }

}
