<div class="app-header-bar">
  <div class="app-header-bar-title">
    <span>{{currentCompany.company_name}}</span>
  </div>
  <div class="app-header-bar-right">
    <div class="ph-offlineHeaderTag"
         *ngIf="isOffline">
      OFFLINE
    </div>
    <div class="ph-menuBtn"
         (click)="toggleMenu()">
      <img class="ph-companyLogo"
           alt=""
           [src]="currentCompany.company_logo">
      <i class="ion ion-md-arrow-dropdown ph-menuIcon">
      </i>
    </div>
  </div>
</div>

<div class="ph-menu-container"
     [ngClass]="{ 'ph-menu-containerClosed': !menuOpen }">

  <button class="ph-menu-greyButton"
          (click)="goToTeamSelect()">
    <span>{{selectedTeam ? selectedTeam.team_name : 'All Employees'}}</span>
    <i class="ion ion-md-swap"
       *ngIf="multipleCompanyTeams">
    </i>
  </button>

  <button>
    <span>Clock Entries</span>
  </button>

  <button (click)="goToTimeHistory()">
    <span>Time History</span>
  </button>

  <div class="ph-menu-break"></div>

  <button class="ph-logoutBtn"
          (click)="logout()">
    <span>Sign Out</span>
  </button>

  <div class="ph-menu-appVersionRow">
    v{{appVersion}}
  </div>

</div>


<ion-content (click)="closeMenuOnBackgroundClick()">

  <ion-refresher slot="fixed"
                 (ionRefresh)="refreshEmployeeClockRecords($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div class="ph-emptyMessage"
       *ngIf="!clockedEmployees.length && !inactiveEmployees.length && !loadingEmployees">
    <i class="ion ion-ios-contacts">
    </i>
    <div>No Employees</div>
  </div>

  <div class="ph-spinner"
       *ngIf="loadingEmployees">
    <ion-spinner></ion-spinner>
  </div>

  <div class="ph-clockView-container">

    <button (click)="viewEmployeeList()"
            *ngIf="initialEmployeeLoad"
            class="btn-success ph-clockView-addEmployee">
      <i class="ion ion-md-add">
      </i>
      <div class="ph-clockView-addEmployeeText">
        Clock In
      </div>
    </button>

    <div class="ph-employeeCard-container"
         *ngFor="let emp of clockedEmployees">

      <div class="ph-employeeCard-imgContainer"
           (click)="emp.isOnBreak ? breakOut(emp) : clockOut(emp)"
           [ngStyle]="{ 'background-color': (emp.isOnBreak || emp.isOffSite) ? '#555' : ''}">

        <img class="ph-employeeCard-img"
             *ngIf="emp.imgUrl"
             [ngStyle]="{ 'filter': (emp.isOnBreak || emp.isOffSite) ? 'brightness(40%)' : ''}"
             [src]="emp.imgUrl">

        <i class="ion ion-ios-contact ph-employeeCard-imgPlaceholder"
           *ngIf="!emp.imgUrl && !emp.isOnBreak && !emp.isOffSite">
        </i>

        <i class="ion ion-ios-pin ph-employeeCard-imgOffSite"
           *ngIf="emp.isOffSite && !emp.isOnBreak">
        </i>

        <div *ngIf="emp.isOnBreak"
             class="ph-employeeCard-onBreak">
          <i class="ion ion-md-play">
          </i>
        </div>
      </div>

      <div class="ph-employeeCard-label"
           (click)="clockOut(emp)">
        {{emp.employee_code}}
      </div>
    </div>

  </div>

</ion-content>
