import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { IonicModule } from '@ionic/angular';

import { PipesModule } from './../../pipes/pipes.module';

import { HistoryComponent } from './history.component';
import { HistoryRecordComponent } from './history-record/history-record.component';
import { HistoryDashComponent } from './history-dash/history-dash.component';

export const HISTORY_CONFIG: any[] = [
  {
    name: 'app.history',
    url: '/history',
    abstract: true,
    component: HistoryComponent
  },
  {
    name: 'app.history.dash',
    url: '/dash',
    component: HistoryDashComponent
  },
  {
    name: 'app.history.record',
    url: '/record',
    component: HistoryRecordComponent,
    params: {
      clockRecord: null
    }
  },
];

@NgModule({
  declarations: [
    HistoryRecordComponent,
    HistoryDashComponent,
    HistoryComponent
  ],
  imports: [
    PipesModule,
    IonicModule,
    FormsModule,
    CommonModule,
    UIRouterModule.forChild({ states: HISTORY_CONFIG })
  ]
})
export class HistoryModule { }
