import { StateService } from '@uirouter/core';
import { Meta } from '@angular/platform-browser';
import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ClockHistoryService } from './../../../services/clock-history/clock-history.service';
import { StateAccessService } from './../../../services/state-access/state-access.service';
import { TeamService } from './../../../services/team/team.service';
import { ProjectService } from './../../../services/project/project.service';
import { AuthService } from './../../../services/auth/auth.service';
import { CompanyService } from './../../../services/company/company.service';
import { UtilService } from './../../../services/util/util.service';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-history-dash',
  templateUrl: './history-dash.component.html',
  styleUrls: ['./history-dash.component.scss']
})
export class HistoryDashComponent implements OnInit, AfterViewInit {

  @ViewChild('monthSliderElement') monthSliderElement: ElementRef;

  monthSlider: any;
  selectedDay: any;
  numDays: number = 31;
  loadingDay: boolean = false;
  menuOpen: boolean = false;

  multipleCompanyTeams: boolean;
  isOffline: boolean;
  appVersion: string;

  currentCompany: any;
  selectedTeam: any;

  constructor(
    public utilService: UtilService,
    public companyService: CompanyService,
    public authService: AuthService,
    public projectService: ProjectService,
    public teamService: TeamService,
    public stateAccessService: StateAccessService,
    public clockHistoryService: ClockHistoryService,
    public stateService: StateService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.initPage();
  }

  ngAfterViewInit() {
    this.monthSliderElement.nativeElement.scrollLeft = this.numDays * 120;
  }

  initPage() {
    this.subscribeToConnectionEvents();
    this.setupDateSlider();

    this.currentCompany = this.companyService.getCompany();
    this.multipleCompanyTeams = this.teamService.hasMultipleTeams();

    this.appVersion = this.meta.getTag('name=appVersion').content;
    this.selectedTeam = this.teamService.getSelectedTeam();
  }

  closeMenuOnBackgroundClick() {
    this.menuOpen = false;
  }

  // Make sure we have the latest data even in situation where coming back from a child state
  ionViewDidEnter() {
    this.menuOpen = false;
  }

  logout() {
    this.authService.logout(true);
    this.authService.goApplet('login');
  }

  goToClockView() {
    this.stateService.go('app.clock.view');
  }

  goToTeamSelect() {
    if (this.multipleCompanyTeams) {
      this.stateService.go('app.teamSelect');
    }
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  setupDateSlider() {
    this.monthSlider = [];
    let date = new Date();

    for (let i = 0; i < this.numDays; i++) {
      this.monthSlider.push({
        date: date,
        dateLabel: moment(date).format("MMM YY"),
        dayLabel: moment(date).format("Do"),
        clockRecords: [],
        clockRecordsLoaded: false,
        selected: i === 0
      });

      date = _.cloneDeep(date);
      date.setDate(date.getDate() - 1);
    }

    this.projectService.loadAllProjects()
      .then(() => {
        this.selectDayInMonthSlider(0);
      });
  }

  selectDayInMonthSlider(index) {
    if (!this.loadingDay) {
      for (let i = 0; i < this.monthSlider.length; i++) {
        this.monthSlider[i].selected = false;
      }

      this.selectedDay = this.monthSlider[index];
      this.selectedDay.selected = true;

      this.loadDaysClockRecords(this.selectedDay);
    }
  }

  loadDaysClockRecords(day) {
    if (!day.clockRecordsLoaded) {
      this.loadingDay = true;

      this.clockHistoryService.loadClockHistory(this.selectedDay.date, this.selectedDay.date)
        .then((clockRecords) => {
          day.clockRecords = clockRecords;
          day.clockRecordsLoaded = true;

          this.loadingDay = false;
        })
        .catch(() => {
          this.loadingDay = false;
        });
    }
  }

  refreshDaysClockRecords(event) {
    if (!this.loadingDay && !!this.selectedDay) {
      this.loadingDay = true;

      this.clockHistoryService.loadClockHistory(this.selectedDay.date, this.selectedDay.date)
        .then((clockRecords) => {
          this.selectedDay.clockRecords = clockRecords;
          this.loadingDay = false;

          event.target.complete();
        })
        .catch(() => {
          this.loadingDay = false;
          event?.target?.complete();
        });
    }
    else {
      event?.target?.complete();
    }
  }

  viewHistoryRecord(clockRecord) {
    this.stateService.go('app.history.record', { clockRecord });
  }

  subscribeToConnectionEvents() {
    this.isOffline = this.utilService.isOffline;

    this.utilService.getPhOnlineEvent().subscribe((isOnline) => {
      this.isOffline = !isOnline;
    });
  }

}
