<div class="ph-clockCamera-backContainer">
  <button id="goBack"
          (click)="goBack()">
    <i class="ion ion-md-arrow-back"></i>
  </button>
</div>

<div class="ph-clockCamera-btnContainer"
     [hidden]="!!photoTaken && !cameraSupported">
  <button id="capture"
          [hidden]="photoTaken || !pageInitialised || !cameraSupported"
          (click)="takePhoto()">
    <i class="ion ion-md-radio-button-on"></i>
  </button>

  <button id="recapture"
          [hidden]="!photoTaken || !cameraSupported"
          (click)="newPhoto($event)">
    <i class="ion ion-md-refresh-circle"></i>
  </button>

  <button id="acceptPhoto"
          [hidden]="!photoTaken || !cameraSupported"
          (click)="clockEmployee($event)">
    <i class="ion ion-ios-checkmark-circle"></i>
  </button>
</div>

<ion-content id="clockCameraContent">

  <div class="ph-spinner"
       *ngIf="!pageInitialised || uploadingPhoto">
    <ion-spinner></ion-spinner>
  </div>

  <div class="ph-clockCamera-imgContainer">
    <div class="ph-clockCamera-imgBorder-top"
         [ngStyle]="{ 'height': borderWidth + 'px' }"
         [hidden]="!pageIsPortrait">
    </div>
    <div class="ph-clockCamera-imgBorder-bottom"
         [ngStyle]="{ 'height': borderWidth + 'px' }"
         [hidden]="!pageIsPortrait">
    </div>
    <div class="ph-clockCamera-imgBorder-left"
         [ngStyle]="{ 'width': borderWidth + 'px' }"
         [hidden]="pageIsPortrait">
    </div>
    <div class="ph-clockCamera-imgBorder-right"
         [ngStyle]="{ 'width': borderWidth + 'px' }"
         [hidden]="pageIsPortrait">
    </div>

    <div class="ph-emptyMessage"
         *ngIf="pageInitialised && !cameraSupported && !photoTaken && !uploadingPhoto">
      <i class="ion ion-md-camera"></i>
      <div>Tap to add photo</div>
    </div>

    <form *ngIf="!cameraSupported">
      <input id="clockCameraInput"
             #clockCameraInput
             (change)="imageUploaded($event)"
             [hidden]="uploadingPhoto"
             type="file"
             accept="image/*"
             capture="user">
    </form>

    <video id="clockCameraVideo"
           [ngStyle]="{
            'opacity': photoTaken || !pageInitialised || !cameraSupported ? 0 : 1,
            'width': this.videoDimensions.width ? this.videoDimensions.width + 'px' : '',
            'height': this.videoDimensions.height ? this.videoDimensions.height + 'px' : ''}"
           autoplay
           playsinline>
    </video>
    <img id="clockCameraImage"
         [ngStyle]="{
            'opacity': photoTaken ? 1 : 0,
          'width': (pageIsPortrait ? pageWidth : pageHeight) + 'px',
          'height': (pageIsPortrait ? pageWidth : pageHeight) + 'px' }"
         src="">
  </div>

</ion-content>
