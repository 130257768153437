import { forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';

import { CompanyDbService } from './../company-db/company-db.service';
import { AuthService } from './../auth/auth.service';
import { UtilService } from './../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  company: any = null;
  userCompany: any = null;

  serviceSetup: boolean = false;

  constructor(
    public utilService: UtilService,
    public authService: AuthService,
    public companyDbService: CompanyDbService
  ) { }

  initialiseService() {
    return new Promise<any>((resolve, reject) => {
      if (this.serviceSetup) {
        resolve(this.company);
      }
      else {
        forkJoin([
          this.loadCompany(),
          this.loadUserCompany()
        ])
          .toPromise()
          .then(() => {
            this.companyDbService.loadCompanyIntoDB(this.company, this.userCompany);
            this.serviceSetup = true;
            resolve(this.company);
          })
          .catch(() => {
            this.companyDbService.loadCompanyFromDB()
              .then((data) => {
                if (data?.company && data?.user_company) {
                  this.company = data.company;
                  this.userCompany = data.user_company;
                  resolve(this.company);
                }
                else {
                  reject({ message: 'No company in CompanyDB' });
                }
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          });
      }
    });
  }

  // Clear service data on logout
  clearServiceData() {
    this.company = null;
    this.userCompany = null;
    this.serviceSetup = false;
  }

  getCompany() {
    return this.company;
  }

  getUserCompany() {
    return this.userCompany;
  }

  getEmployeeKey() {
    return this.userCompany?.employee_key;
  }

  loadCompany(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.utilService.APIGet('company', null)
        .then((data) => {
          this.company = CompanyService.setupCompany(data[0]);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loadUserCompany(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.utilService.APIGet('useraccesscompany', null, false, null, true)
        .then((data) => {
          this.userCompany = CompanyService.setupUserCompany(data);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static setupCompany(company: any): any {
    company.employment_finish_date = UtilService.dateStringToDate(company.employment_finish_date, null);
    company.subscription_trial_date = UtilService.dateStringToDate(company.subscription_trial_date, null);
    company.suspension_date = UtilService.dateStringToDate(company.suspension_date, null);
    company.latest_pay = UtilService.dateStringToDate(company.latest_pay, null);
    company.cancelled_date = UtilService.dateStringToDate(company.cancelled_date, null);

    return company;
  }

  static setupUserCompany(userCompany: any): any {

    return userCompany;
  }

}
