import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterModule } from '@uirouter/angular';
import { IonicModule } from '@ionic/angular';

import { PipesModule } from './../../pipes/pipes.module';

import { ClockComponent } from './clock.component';
import { ClockCameraComponent } from './clock-camera/clock-camera.component';
import { ClockOutComponent } from './clock-out/clock-out.component';
import { ClockViewComponent } from './clock-view/clock-view.component';
import { ClockEmployeeComponent } from './clock-employee/clock-employee.component';

export const CLOCK_CONFIG: any[] = [
  {
    name: 'app.clock',
    url: '/clock',
    abstract: true,
    component: ClockComponent
  },
  {
    name: 'app.clock.view',
    url: '/view',
    component: ClockViewComponent
  },
  {
    name: 'app.clock.camera',
    url: '/camera',
    component: ClockCameraComponent,
    params: {
      employee: null
    }
  },
  {
    name: 'app.clock.out',
    url: '/out',
    component: ClockOutComponent,
    params: {
      clockEmployee: null
    }
  },
  {
    name: 'app.clock.employee',
    url: '/employee',
    component: ClockEmployeeComponent
  }
];


@NgModule({
  declarations: [
    ClockComponent,
    ClockCameraComponent,
    ClockOutComponent,
    ClockViewComponent,
    ClockEmployeeComponent
  ],
  imports: [
    PipesModule,
    IonicModule,
    FormsModule,
    CommonModule,
    UIRouterModule.forChild({ states: CLOCK_CONFIG })
  ]
})
export class ClockModule { }
