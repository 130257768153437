import { Pipe, PipeTransform } from '@angular/core';

import { UtilService } from './../../services/util/util.service';

import * as moment from 'moment';

@Pipe({
  name: 'clockTimerSecondsPipe'
})
export class ClockTimerSecondsPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let seconds = moment.duration(value, 'seconds').seconds();

    return UtilService.padNumber(seconds, 2, false);
  }

}
