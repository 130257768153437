<div class="app-header-bar">
  <div class="app-header-bar-left">
    <div class="app-header-back"
         (click)="goBack()">
      <i class="ion ion-md-arrow-back"></i>
    </div>
  </div>
  <div class="app-header-bar-title">
    <span>Clock Out</span>
  </div>
  <div class="app-header-bar-right">
    <div class="ph-offlineHeaderTag"
         *ngIf="isOffline">
      OFFLINE
    </div>
  </div>
</div>

<ion-content *ngIf="clockEmployee">

  <div class="ph-clockOut-headerRow">
    <div class="ph-clockOut-headerDate">
      {{clockEmployee.clockRecord.clock_in_time | date : 'd MMMM,'}}
      <span>{{clockEmployee.clockRecord.clock_in_time | date : 'yyyy'}}</span>
    </div>
    <div class="ph-clockOut-headerName">
      {{clockEmployee.employee_code}}
    </div>
    <div *ngIf="clockEmployee.isOffSite"
         class="ph-clockOut-headerOffsite">
      <i class="ion ion-ios-pin"></i> Offsite
    </div>
  </div>

  <div class="ph-employeeCard-container ph-employeeCard-containerLarge">
    <div class="ph-employeeCard-imgContainer"
         [ngStyle]="{ 'background-color': (clockEmployee.isOnBreak || clockEmployee.isOffSite) ? '#555' : ''}">

      <img class="ph-employeeCard-img"
           *ngIf="clockEmployee.imgUrl"
           [ngStyle]="{ 'filter': (clockEmployee.isOnBreak || clockEmployee.isOffSite) ? 'brightness(40%)' : ''}"
           [src]="clockEmployee.imgUrl">

      <i class="ion ion-ios-contacts ph-employeeCard-imgPlaceholder"
         *ngIf="!clockEmployee.imgUrl && !clockEmployee.isOnBreak && !clockEmployee.isOffSite">
      </i>

      <i class="ion ion-ios-pin ph-employeeCard-imgOffSite"
         *ngIf="clockEmployee.isOffSite && !clockEmployee.isOnBreak">
      </i>

      <div *ngIf="clockEmployee.isOnBreak"
           (click)="breakOut()"
           class="ph-employeeCard-onBreak">
        <i class="ion ion-ios-play">
        </i>
      </div>
    </div>

    <div class="ph-employeeCard-label">
      {{clockEmployee.clockRecord.clock_in_time | date : 'h:mm'}}<span>{{clockEmployee.clockRecord.clock_in_time | date
        : 'a' | lowercase}}</span>
    </div>
  </div>

  <div class="ph-clockOut-project"
       *ngIf="clockEmployee.clockRecord.project">
    <div class="ph-clockOut-projectColor"
         [ngStyle]="{ 'background-color': clockEmployee.clockRecord.project.project_colour }">
    </div>
    <div class="ph-clockOut-projectTitle">
      {{clockEmployee.clockRecord.project.project_title}}
    </div>
  </div>

  <div class="ph-clockOut-timer">

    <div class="ph-clockOut-timerIcon"
         *ngIf="clockFlasher">
    </div>
    <div class="ph-clockOut-timerMain">
      {{clockSeconds | clockTimerMainPipe}}
    </div>

  </div>

  <div class="ph-clockOut-timer ph-clockOut-breakTimer"
       *ngIf="clockEmployee.isOnBreak">

    <div class="ph-clockOut-timerIcon"
         *ngIf="breakFlasher">
    </div>
    <div class="ph-clockOut-timerMain">
      {{activeBreakSeconds | clockTimerMainPipe}}
    </div>

  </div>

  <div class="ph-clockOut-btnRow">

    <div class="ph-clockOut-btnContainer ph-clockOut-btnContainer-breakOut"
         *ngIf="clockEmployee.isOnBreak">
      <button ion-button
              icon-start
              [disabled]="loading"
              (click)="breakOut()"
              class="ph-clockOut-btn btn-play">
        <i class="ion ion-ios-play">
        </i>
        End Break
      </button>
    </div>

    <div class="ph-clockOut-btnContainer"
         *ngIf="!clockEmployee.isOnBreak">
      <button ion-button
              icon-start
              [disabled]="loading || clockSeconds + activeBreakSeconds + finishedBreakSeconds > maxTotalSeconds"
              (click)="breakIn()"
              class="ph-clockOut-btn btn-pause">
        <i class="ion ion-ios-pause">
        </i>
        Start Break
      </button>
    </div>

    <div class="ph-clockOut-btnContainer"
         *ngIf="!clockEmployee.isOnBreak">
      <button ion-button
              icon-start
              [disabled]="loading"
              (click)="clockOut()"
              class="ph-clockOut-btn btn-stop">
        <i class="ion ion-ios-square">
        </i>
        Clock Out
      </button>
    </div>

  </div>

  <div class="ph-clockOut-clockCapMessage"
       *ngIf="clockSeconds + activeBreakSeconds + finishedBreakSeconds > maxTotalSeconds">
    This clock entry will be trimmed when you clock out due to the total duration exceeding 24 hours
  </div>

  <div class="ph-cardLabel"
       *ngIf="clockEmployee.clockRecord.clock_breaks.length">
    Breaks
  </div>
  <div class="ph-clockBreak-container"
       *ngIf="clockEmployee.clockRecord.clock_breaks.length">

    <div class="ph-clockBreak-row"
         *ngFor="let clockBreak of clockEmployee.clockRecord.clock_breaks"
         [ngClass]="{ 'ph-clockBreak-activeBreakRow': !clockBreak.end_time }">
      <div class="ph-clockBreak-time">
        {{clockBreak.start_time | date : 'shortTime'}}
      </div>
      <div class="ph-clockBreak-timeDivider">-
      </div>
      <div class="ph-clockBreak-time"
           *ngIf="clockBreak.end_time">
        {{clockBreak.end_time | date : 'shortTime'}}
      </div>

      <div class="ph-clockBreak-duration"
           *ngIf="clockBreak.end_time">
        {{clockBreak.break_duration | hoursToTimePipe}}
      </div>
      <div class="ph-clockBreak-duration"
           *ngIf="!clockBreak.end_time">
        {{activeBreakSeconds | clockTimerMainPipe}}
      </div>
    </div>

  </div>

  <div class="ph-cardLabel">
    Notes
  </div>
  <div class="ph-clockNote-container">

    <div class="ph-clockNote-note"
         *ngFor="let note of clockEmployee.clockRecord.clock_notes;">
      <p>{{note.note}}</p>
      <button ion-button
              (click)="deleteNote(note)"
              class="ph-clockNote-delete">
        <i class="ion ion-ios-close">
        </i>
      </button>
    </div>

    <textarea #noteTextArea
              class="text-input ph-textArea"
              placeholder="Add a note..."
              [(ngModel)]="note"
              *ngIf="addingNote">
    </textarea>

    <div class="ph-clockNote-buttonRow">

      <button ion-button
              (click)="addNote()"
              class="ph-clockNote-addBtn"
              *ngIf="!addingNote">
        <i class="ion ion-md-add">
        </i>
      </button>

      <button ion-button
              (click)="saveNote()"
              class="ph-clockNote-saveBtn"
              *ngIf="addingNote">
        <i class="ion ion-md-checkmark">
        </i>
      </button>
      <button ion-button
              (click)="cancelNote()"
              class="ph-clockNote-cancelBtn"
              *ngIf="addingNote">
        <i class="ion ion-md-close">
        </i>
      </button>

    </div>
  </div>

</ion-content>
