import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Component, OnInit } from '@angular/core';

import { UtilService } from './../../../services/util/util.service';
import { ProjectService } from './../../../services/project/project.service';

import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-history-record',
  templateUrl: './history-record.component.html',
  styleUrls: ['./history-record.component.scss']
})
export class HistoryRecordComponent implements OnInit {

  clockRecord: any;

  // Date Objects
  startTime: any;
  endTime: any;
  // Dates in ISO string format
  startTimeString: string;
  endTimeString: string;

  // Decimals
  duration: number;
  breakDuration: number;
  // Dates in ISO string format
  durationString: string;
  breakDurationString: string;

  durationSeconds: number;
  breakDurationSeconds: number;

  allProjects: any[] = [];
  selectedProject: any;

  multiDayClock: boolean;

  isOffline: boolean;

  constructor(
    public projectService: ProjectService,
    public utilService: UtilService,
    public stateService: StateService,
    public uiRouterGlobals: UIRouterGlobals
  ) { }

  ngOnInit(): void {
    this.subscribeToConnectionEvents();
    this.initPage();
  }

  goBack() {
    this.stateService.go('app.history.dash');
  }

  initPage() {
    this.clockRecord = this.uiRouterGlobals.params.clockRecord;

    if (!this.clockRecord) {
      this.goBack()
    }
    else {
      this.initSelectedProject();
      this.initDateValues(this.clockRecord.clock_in_time, this.clockRecord.clock_out_time, this.clockRecord.break_duration);
      this.multiDayClock = !moment(this.clockRecord.clock_in_time).isSame(moment(this.clockRecord.clock_out_time), "day");
    }
  }

  initSelectedProject() {
    this.allProjects = this.projectService.getAllTimesheetHoursProjects();
    this.selectedProject = null;

    if (this.clockRecord.project) {
      for (let i = 0; i < this.allProjects.length; i++) {
        let project = this.allProjects[i];

        if (project.project_key == this.clockRecord.project.project_key) {
          this.selectedProject = project;
        }
      }
    }
  }

  /**
   * Ionic datetime inputs require dates as a string in ISO format
   *
   * @param {Date} start
   * @param {Date} end
   * @param {number} breakDur
   */
  initDateValues(start, end, breakDur) {
    if (breakDur || breakDur === 0) {
      this.breakDuration = _.cloneDeep(breakDur);
      this.breakDurationSeconds = Math.ceil(this.breakDuration * 60 * 60);

      let breakDurationDate = UtilService.hoursDecimalAsDate(breakDur);
      this.breakDurationString = UtilService.dateToLocalTimeISOString(breakDurationDate);
    }

    if (start && end) {
      start.setSeconds(0, 0);
      end.setSeconds(0, 0);

      this.startTime = _.cloneDeep(start);
      this.endTime = _.cloneDeep(end);

      this.startTimeString = UtilService.dateToLocalTimeISOString(this.startTime);
      this.endTimeString = UtilService.dateToLocalTimeISOString(this.endTime);

      this.duration = UtilService.differenceBetweenTwoDatesAsHoursDecimal(this.startTime, this.endTime) - (this.breakDuration || 0);
      this.durationString = UtilService.hoursDecimalToLocalTimeISOString(this.duration);
      this.durationSeconds = Math.ceil(this.duration * 60 * 60);
    }
    else {
      this.startTime = null;
      this.endTime = null;
      this.breakDuration = null;
    }
  }

  timeChanged() {

  }

  durationChanged() {

  }

  breakDurationChanged() {

  }

  subscribeToConnectionEvents() {
    this.isOffline = this.utilService.isOffline;

    this.utilService.getPhOnlineEvent().subscribe((isOnline) => {
      this.isOffline = !isOnline;
    });
  }

}
