import { StateService } from '@uirouter/core';
import { forkJoin } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

import { StateAccessService } from './../../../services/state-access/state-access.service';
import { EmployeeService } from './../../../services/employee/employee.service';
import { TeamService } from './../../../services/team/team.service';
import { UtilService } from './../../../services/util/util.service';
import { AuthService } from './../../../services/auth/auth.service';
import { CompanyService } from './../../../services/company/company.service';

@Component({
  selector: 'app-team-select',
  templateUrl: './team-select.component.html',
  styleUrls: ['./team-select.component.scss']
})
export class TeamSelectComponent implements OnInit {

  menuOpen: boolean = false;

  managerTeams: any[];
  allEmployees: any[];
  teamEmployeeProjects: any;

  selectedTeam: any = null;

  currentCompany: any;
  appVersion: string;

  multipleCompanyTeams: boolean;
  loadingTeams: boolean = false;
  isOffline: boolean;

  constructor(
    public companyService: CompanyService,
    public authService: AuthService,
    public utilService: UtilService,
    public teamService: TeamService,
    public employeeService: EmployeeService,
    public stateAccessService: StateAccessService,
    public stateService: StateService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.initPage();
  }

  setSelectedTeam(team) {
    this.teamService.setSelectedTeam(team);
    this.selectedTeam = this.teamService.getSelectedTeam();

    this.goToClockView();
  }

  initPage() {
    this.appVersion = this.meta.getTag('name=appVersion').content;

    this.currentCompany = this.companyService.getCompany();
    this.multipleCompanyTeams = this.teamService.hasMultipleTeams();

    this.managerTeams = this.teamService.getManagerTeams();
    this.allEmployees = this.employeeService.getEmployees();

    this.selectedTeam = this.teamService.getSelectedTeam();
  }

  refreshTeams(event) {
    this.loadingTeams = true;

    forkJoin([
      this.employeeService.loadEmployees(),
      this.teamService.loadEmployeeTeams()
    ]).subscribe(
      () => {
        this.managerTeams = this.teamService.getManagerTeams();
        this.allEmployees = this.employeeService.getEmployees();

        this.loadingTeams = false;
        event?.target?.complete();
      },
      () => {
        this.loadingTeams = false;
        event?.target?.complete();
      }
    );
  }

  logout() {
    this.authService.logout(true);
    this.authService.goApplet('login');
  }

  goToClockView() {
    this.stateService.go('app.clock.view');
  }

  goToTimeHistory() {
    this.stateService.go('app.history.dash');
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  subscribeToConnectionEvents() {
    this.isOffline = this.utilService.isOffline;

    this.utilService.getPhOnlineEvent().subscribe((isOnline) => {
      this.isOffline = !isOnline;
    });
  }

}
