import { StateService } from '@uirouter/core';
import { Component, OnInit } from '@angular/core';

import { EmployeeService } from './../../../services/employee/employee.service';
import { UtilService } from './../../../services/util/util.service';

@Component({
  selector: 'app-clock-employee',
  templateUrl: './clock-employee.component.html',
  styleUrls: ['./clock-employee.component.scss']
})
export class ClockEmployeeComponent implements OnInit {

  inactiveEmployees: any[];
  visibleEmployeeCount: number;

  loadingEmployees: boolean = true;

  employeeSearch: string;

  isOffline: boolean;

  constructor(
    public utilService: UtilService,
    public employeeService: EmployeeService,
    public stateService: StateService
  ) { }

  ngOnInit(): void {
    this.employeeSearch = "";
    this.subscribeToConnectionEvents();
    this.setupEmployeeList();
  }

  goBack(){
    this.stateService.go('app.clock.view');
  }

  setupEmployeeList() {
    this.employeeService.getInactiveEmployees()
      .then((employees) => {
        this.inactiveEmployees = employees;

        this.filterEmployees();
        this.loadingEmployees = false;
      })
      .catch(() => {
        this.stateService.go('app.clock.view');
      })
  }

  filterEmployees() {
    let search = this.employeeSearch.toUpperCase();
    this.visibleEmployeeCount = 0;

    for (let i = 0; i < this.inactiveEmployees.length; i++) {
      let emp = this.inactiveEmployees[i];
      emp.visible = !search || emp.employee_code.toUpperCase().indexOf(search) !== -1;

      if (emp.visible) {
        this.visibleEmployeeCount++;
      }
    }
  }

  clockIn(employee) {
    this.stateService.go('app.clock.camera', { employee });
  }

  subscribeToConnectionEvents() {
    this.isOffline = this.utilService.isOffline;

    this.utilService.getPhOnlineEvent().subscribe((isOnline) => {
      this.isOffline = !isOnline;
    });
  }

}
