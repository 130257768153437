import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import PouchDB from 'pouchdb-browser';

@Injectable({
  providedIn: 'root'
})
export class CompanyDbService {

  db: any;

  constructor() { }


  initDB() {
    try {
      this.db = new PouchDB("ph-droppah-company", {
        adapter: "idb"
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  clearServiceData(clearDatabases) {
    if (clearDatabases) {
      this.clearCompaniesFromDB();
    }
  }

  loadCompanyIntoDB(company: any, user_company: any) {
    return new Promise<void>((resolve, reject) => {
      if (this.db) {

        this.db.get('SHIFT_COMPANY')
          // Overwriting an existing doc
          .then((doc) => {
            doPut(company, user_company, doc._rev);
          })
          // Creating new doc
          .catch(() => {
            doPut(company, user_company, null);
          });

        const doPut = (company, user_company, _rev) => {
          const data = {
            _id: 'SHIFT_COMPANY',
            _rev,
            company,
            user_company
          };

          this.db.put(data)
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            })
        };
      }
      else {
        reject();
      }
    });
  }

  loadCompanyFromDB(): Promise<{ company: any, user_company: any }> {
    return new Promise<{ company: any, user_company: any }>((resolve, reject) => {
      if (this.db) {
        const _id = 'SHIFT_COMPANY';

        this.db.get(_id)
          .then((doc) => {
            resolve({
              company: doc?.company,
              user_company: doc?.user_company
            });
          })
          .catch((err) => {
            console.log(err);
            resolve(null);
          });
      }
      else {
        reject();
      }
    });
  }

  clearCompaniesFromDB() {
    return new Promise<void>((resolve) => {
      if (this.db) {

        this.db.destroy()
          .then(() => {
            this.initDB();
            resolve();
          })
          .catch((err) => {
            console.log(err);
            resolve();
          });
      }
      else {
        resolve();
      }
    });
  }

}
