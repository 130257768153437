import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Component, OnInit } from '@angular/core';

import { AuthService } from './../../../services/auth/auth.service';
import { UtilService } from './../../../services/util/util.service';

declare var Base64;

@Component({
  selector: 'app-login-external',
  templateUrl: './login-external.component.html',
  styleUrls: ['./login-external.component.scss']
})
export class LoginExternalComponent implements OnInit {

  params: any;

  constructor(
    private uiRouterGlobals: UIRouterGlobals,
    public stateService: StateService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    const encoded_params = this.uiRouterGlobals.params.urlParams;

    if (!!encoded_params) {
      this.params = UtilService.parseJSON(Base64.decode(encoded_params));

      if (
        this.params &&
        this.params.session_key &&
        this.params.company_code &&
        this.params.user_access_company_key &&
        this.params.return_session_key
      ) {
        this.externalLogin();
      }
      else {
        this.authService.goApplet('login');
      }
    }
    else {
      this.authService.goApplet('login');
    }
  }

  externalLogin() {
    this.authService.externalLogin(
      this.params.session_key,
      this.params.company_code,
      this.params.user_access_company_key,
      this.params.return_session_key
    );

    this.stateService.go('splash');
  }

  logout() {
    this.authService.logout();
    this.authService.goApplet('login');
  }
}
