import { Injectable } from '@angular/core';

export type Auth = {
  session_key: string,
  company_code: string,
  return_session_key: string,
  return_login_token: string,
  user_access_company_key: number
};

@Injectable({
  providedIn: 'root'
})
export class StateDataService {

  localStorage: any = window.localStorage;
  sessionStorage: any = window.sessionStorage;

  private readonly _sessionCachePrefix: string = 'ph_shift_';
  private readonly _localCachePrefix: string = 'ph_shift_';

  constructor() { }

  // Session storage
  cacheComponentSessionData(componentName: string, dataName: string, data: any) {
    this._setSessionStorageData(this._sessionCachePrefix + componentName + '_' + dataName, data);
  }

  getCachedComponentSessionData(componentName: string, dataName: string): any {
    return this._getSessionStorageData(this._sessionCachePrefix + componentName + '_' + dataName);
  }

  clearCachedComponentSessionData(componentName: string, dataName: string) {
    this._removeSessionStorageData(this._sessionCachePrefix + componentName + '_' + dataName);
  }

  // Local storage
  cacheComponentLocalData(componentName: string, dataName: string, data: any) {
    this._setLocalStorageData(this._localCachePrefix + componentName + '_' + dataName, data);
  }

  getCachedComponentLocalData(componentName: string, dataName: string): any {
    return this._getLocalStorageData(this._localCachePrefix + componentName + '_' + dataName);
  }

  clearCachedComponentLocalData(componentName: string, dataName: string) {
    this._removeLocalStorageData(this._localCachePrefix + componentName + '_' + dataName);
  }

  clearAllCachedData() {
    for (const key of Object.keys(this.localStorage)) {
      this.localStorage.removeItem(key);
    }
    for (const key of Object.keys(this.sessionStorage)) {
      this.sessionStorage.removeItem(key);
    }
  }

  ///////////////////////////////////////////////////////////

  get auth(): Auth {
    const session_auth = this._getSessionStorageData(this._sessionCachePrefix + 'session_auth');
    const local_auth = this._getLocalStorageData(this._localCachePrefix + 'local_auth');

    return {
      session_key: session_auth?.session_key || null,
      company_code: session_auth?.company_code || null,
      return_session_key: session_auth?.return_session_key || null,
      return_login_token: local_auth?.return_login_token || null,
      user_access_company_key: session_auth?.user_access_company_key || null
    };
  }
  set auth(auth: Auth) {
    if (this._localCachePrefix !== null) {
      if (!!auth) {
        const session_auth = {
          session_key: auth.session_key,
          company_code: auth.company_code,
          return_session_key: auth.return_session_key,
          user_access_company_key: auth.user_access_company_key
        };
        const local_auth = {
          return_login_token: auth.return_login_token
        };

        this._setSessionStorageData(this._sessionCachePrefix + 'session_auth', session_auth);
        this._setLocalStorageData(this._localCachePrefix + 'local_auth', local_auth);
      }
      else {
        this._removeSessionStorageData(this._sessionCachePrefix + 'session_auth');
        this._removeLocalStorageData(this._localCachePrefix + 'local_auth');
      }
    }
  }

  ///////////////////////////////////////////////////////////

  private _getLocalStorageData(dataName: string): any {
    const data = this.localStorage.getItem(dataName);
    return data ? StateDataService._parseJSON(data) : null;
  }

  private _setLocalStorageData(dataName: string, data: any): void {
    this.localStorage.setItem(dataName, JSON.stringify(data));
  }

  private _removeLocalStorageData(dataName: string): void {
    this.localStorage.removeItem(dataName);
  }

  private _getSessionStorageData(dataName: string) {
    const data = this.sessionStorage.getItem(dataName);
    return data ? StateDataService._parseJSON(data) : null;
  }

  private _setSessionStorageData(dataName: string, data: any) {
    this.sessionStorage.setItem(dataName, JSON.stringify(data));
  }

  private _removeSessionStorageData(dataName: string) {
    this.sessionStorage.removeItem(dataName);
  }

  private static _parseJSON(data: any): any {
    try {
      return JSON.parse(data);
    }
    catch (err) {
      return data;
    }
  }


}
