import { Injectable } from '@angular/core';

declare var Base64;

@Injectable({
  providedIn: 'root'
})
export class PinService {

  pin: string;

  localStorage: any = window.localStorage;

  constructor() { }

  initManagerPin() {
    let pin = this.localStorage.getItem("ph_shift_managerPin");
    this.pin = pin || null;
  }

  setManagerPin(pin) {
    this.pin = Base64.encode(pin);
    this.localStorage.setItem("ph_shift_managerPin", this.pin);
  }

  authenticateManagerPin(pin) {
    return !!this.pin && !!pin && this.pin === Base64.encode(pin);
  }

  managerPinExists() {
    return !!this.pin;
  }

  clearProviderData() {
    this.localStorage.removeItem("ph_shift_managerPin");
  }

}
